import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useAuthStore } from '../../store/store'
import { generateOTP, verifyOTP } from '../../helper/helper';
import { useNavigate } from 'react-router-dom'
import './Recovery.scss';

const Recovery = () => {

    const { username } = useAuthStore(state => state.auth);
    const [OTP, setOTP] = useState();
    const navigate = useNavigate()

    useEffect(() => {
        generateOTP(username).then((OTP) => {
            console.log(OTP)
            if (OTP) return toast.success('OTP has been send to your email!');
            return toast.error('Problem while generating OTP!')
        })
    }, [username]);

    async function onSubmit(e) {
        e.preventDefault();
        try {
            let { status } = await verifyOTP({ username, code: OTP })
            if (status === 201) {
                toast.success('Verify Successfully!')
                return navigate('/reset')
            }
        } catch (error) {
            return toast.error('Wront OTP! Check email again!')
        }
    }

    // handler of resend OTP
    function resendOTP() {

        let sentPromise = generateOTP(username);

        toast.promise(sentPromise,
            {
                loading: 'Sending...',
                success: <b>OTP has been send to your email!</b>,
                error: <b>Could not Send it!</b>,
            }
        );

        sentPromise.then((OTP) => {

        });

    }

    return (
        <div className='recovery_container'>
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className='block'>
                <div className='top'>
                   

                </div>
                <form className='middle' onSubmit={onSubmit}>
                    <div className='middle-div'>
                        <div className='middle-div-div'>
                            <span className='middle-span'>
                                Enter 6 digit OTP sent to your email address.
                            </span>
                            <input onChange={(e) => setOTP(e.target.value)} className='middle-input' type="text" />
                        </div>
                        <button className='middle-button' type='submit'>Recover</button>
                    </div>
                </form>
                <div className="bottom" onClick={resendOTP}>
                    <span className='bottom-span'>Can't get OTP? </span>
                    <div className='bottom-span1'><p>Click here!</p></div>
                </div>
            </div>
        </div>
    )
}

export default Recovery