import React, { useState, useEffect } from 'react'
import './ManualInputData.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
/* import { useFormik } from 'formik'; */
import toast, { Toaster } from 'react-hot-toast';
import { /* deleteManualData */ sumbitManualSkuList, getManualSkuList /* getAllUsersData, */ /* editManualData, submitManualInputData, getManualInputData */ } from '../../helper/helper'
/* import ReadOnlyRowManual from '../../components/ReadOnlyRowManual'
import EditableRowManual from '../../components/EditableRowManual' */
/* import { storeNameList } from '../../components/ReusableFunctions' */
import ReactLoading from 'react-loading';
import * as XLSX from 'xlsx';
import Tooltip from '@mui/material/Tooltip';

const ManualInputData = () => {
    /* const [filterEmp, setFilterEmp] = useState('');
    const [userdata, setUserdata] = useState([]);
    const [currentStoreSelected, setCurrentStoreSelected] = useState('');
    const [currentStoreName, setCurrentStoreName] = useState('');
    const [selectedStore, setSelectedStore] = useState([]);
    const [currentEmpSelected, setCurrentEmpSelected] = useState('')
    const [currentEmpName, setCurrentEmpName] = useState('')
    const [storeID, setStoreID] = useState('');
    const [manualDataList, setManualDataList] = useState([]);
    const [manualData, setManualData] = useState([])
    const [editEmployeeId, setEditEmployeeId] = useState(null);
    const [editFormData, setEditFormData] = useState({
        employee: '',
        accessories: '',
        applecare: '',
    }) */
    const [manualFile, setManualFile] = useState([]);
    const [skuApi, setSkuApi] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        async function getRawUserData() {
            setIsLoading(true);
            try {
                let { data } = await getManualSkuList();
                setSkuApi(data[0].manualFile)
                if (data[0].manualFile.length > 0) {
                    setIsLoading(false);
                }
            } catch (error) {
                return error
            }
        }
        getRawUserData()
    }, []);


    /* const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString); */

    /*  useEffect(() => {
         const data = getManualInputData({ endDate });
         console.log(data);
         data.then((res) => {
             setManualDataList(res.data)
             setManualData(res.data)
         })
     }, [endDate])
 
     function storeNameExtractor(userdata) {
         const storeNames = [];
         userdata?.forEach((emp) => {
             if (emp.email !== "admin@vyadom.com") {
                 if (!storeNames.includes(emp.fullname)) {
                     storeNames.push(emp.fullname)
                 }
             }
         })
         setFilterEmp(storeNames);
     }
 
     const handleEmployeeChange = (e) => {
         e.preventDefault();
 
         const { value } = e.target
         if (value !== "") {
             let exist = []
             let existEmail = []
             userdata.forEach((emp) => {
                 if (emp.fullname === value) {
                     exist.push(emp.stores)
                     existEmail.push(emp.username)
 
                     setCurrentEmpName(emp.fullname)
                 }
             })
             let stores = exist.toString().split(',');
             setSelectedStore(stores)
             setCurrentEmpSelected(existEmail[0])
             let existData = []
             manualDataList.forEach(emp => {
                 if (emp.employee === existEmail[0]) {
                     existData.push(emp);
                 }
             })
             setManualData(existData);
         } else {
             setManualData(manualDataList);
         }
 
 
     }
 
     const handleStoreChange = (e) => {
         const { value } = e.target;
         setCurrentStoreSelected(storeNameList[value])
         setCurrentStoreName(value)
         setStoreID(value)
     }
 
     const formik = useFormik({
         initialValues: {
             accessories: '',
             applecare: '',
         },
         validateOnBlur: false,
         validateOnChange: false,
         onSubmit: async values => {
             values = { ...values, employee: currentEmpSelected, store: currentStoreSelected, startdate: firstDayOfMonthString, enddate: endDate, storeid: storeID }
             console.log(values);
             let submitPromise = submitManualInputData(values)
             toast.promise(submitPromise, {
                 loading: 'Uploading...',
                 success: <b>Uploaded Successfully...!</b>,
                 error: <b>Not uploaded</b>
             });
         }
     })
 
     const handleEdit = (event, emp) => {
         event.preventDefault();
         setEditEmployeeId(emp._id);
         const formValues = {
             employee: emp.employee,
             accessories: emp.accessories,
             applecare: emp.applecare
         }
         setEditFormData(formValues)
     }
 
     const handleDeleteClick = async (id) => {
         console.log(id);
         if (window.confirm(`Are you sure you want to delete ${id}'s data?`)) {
             let data = await deleteManualData({ id });
             if (data) {
                 alert("data deleted successfully");
             }
         } else {
             console.log("not done")
         }
     }
 
     const handleEditFormChange = (event) => {
         event.preventDefault();
         const fieldName = event.target.getAttribute("name");
         const fieldValue = event.target.value;
         const newFormData = { ...editFormData }
         newFormData[fieldName] = fieldValue;
         setEditFormData(newFormData)
     }
 
     const handleCancelClick = () => {
         setEditEmployeeId(null);
     }
 
     const handleEditFormSubmit = (event) => {
         event.preventDefault();
         const editManual = {
             _id: editEmployeeId,
             employee: editFormData.employee,
             accessories: editFormData.accessories,
             applecare: editFormData.applecare,
         }
         const editRowPromise = editManualData({
             _id: editManual._id,
             accessories: editManual.accessories,
             applecare: editManual.applecare
         })
         toast.promise(editRowPromise, {
             loading: 'Editing...',
             success: <b>Editied Successfully...!</b>,
             error: <b>Could not Edit.</b>
         })
         setEditEmployeeId(null);
     } */
    const handleFileUpload = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            // Extracting store names
            const stores = excelData.slice(1,);
            setManualFile(stores)
        };
        reader.readAsArrayBuffer(file);

    };
    const onSubmit = (event) => {
        event.preventDefault();
        let promise = sumbitManualSkuList({ manualFile });
        toast.promise(promise, {
            loading: 'Submitting...',
            success: <b>Submitted Successfully...!</b>,
            error: <b>Could not Submit.</b>
        })
    }

    const headers = [
        { key: 'sku', label: 'SKU' },
        { key: 'name', label: 'Accessory Name' },
        { key: 'cost', label: 'Cost' },
        { key: 'msrp', label: 'MSRP' },
    ]

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = skuApi.filter(item =>
        item[0].toLowerCase().includes(searchTerm.toLowerCase()) ||
        item[1].toLowerCase().includes(searchTerm.toLowerCase())
    );

    const clearSearch = () => {
        setSearchTerm('');
    };

    return (
        <div className='manual'>
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <AdminSidebar />
            <div className="manual-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="manual-block">
                    <div className="manual-block-container">
                        {/* <div className="filter-menu">
                            <label htmlFor="startDate">From:</label>
                            <input
                                type="date"
                                id="startDate"
                                className='filter-datemenu-input'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <label htmlFor="endDate">To:</label>
                            <input
                                type="date"
                                id="endDate"
                                className='filter-datemenu-input'
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            <label htmlFor='employeelist' >Employee:</label>
                            <select name="employeelist" id="employeelist" onChange={handleEmployeeChange} value={currentEmpName}>
                                <option value="">Select an Employee</option>
                                {filterEmp && filterEmp?.map((emp, index) => (
                                    <option key={index} value={emp}>
                                        {emp}
                                    </option>
                                ))}
                            </select>

                            <label >Store:</label>
                            <select name="storeslist" id="storeslist" onChange={handleStoreChange} value={currentStoreName}>
                                <option value="">Select a Store</option>
                                {selectedStore && selectedStore?.map((store, index) => (
                                    <option key={index} value={store}>
                                        {storeNameList[store]}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        <div className='bot'>
                            {/* <div className="input-form">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='forminput'>
                                        <div className='form-input-1'>
                                            <label>Accessories(MTD):</label>
                                            <input {...formik.getFieldProps('accessories')} type='number' placeholder='Enter Sales Value Here...' />
                                        </div>
                                    </div>
                                    <div className='forminput'>
                                        <div className='form-input-1'>
                                            <label>Apple Care(MTD):</label>
                                            <input {...formik.getFieldProps('applecare')} type='number' placeholder='Enter Qty Here...' />
                                        </div>
                                    </div>
                                    <div className='submit-button'>
                                        <button onSubmit={formik.handleSubmit} type='submit'>Submit</button>
                                    </div>
                                </form>

                            </div> */}
                            {/* <div className='render-values'>
                                <div className='manual_Table'>
                                    <form onSubmit={handleEditFormSubmit}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Employee</th>
                                                    <th>Store</th>
                                                    <th>Accessories</th>
                                                    <th>Apple Care</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {manualData?.map((emp, index) => (
                                                    <Fragment key={index}>
                                                        {editEmployeeId === emp._id ?
                                                            (
                                                                <EditableRowManual
                                                                    editFormData={editFormData}
                                                                    handleEditFormChange={handleEditFormChange}
                                                                    handleCancelClick={handleCancelClick}
                                                                />
                                                            ) : (
                                                                <ReadOnlyRowManual
                                                                    emp={emp}
                                                                    handleEdit={handleEdit}
                                                                    handleDeleteClick={handleDeleteClick}
                                                                />
                                                            )}

                                                    </Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div> */}
                            <div className='top'>

                                <div className='manual-title-div'>
                                    <h2 className='manual-title'>Upload Accessory-SKU file </h2>
                                </div>

                                <div className="uploadFile">
                                    <input type="file" onChange={handleFileUpload} />
                                </div>
                                <div className='bottom-button'>
                                    <button onClick={onSubmit} className="upload-button">Upload</button>
                                </div>
                            </div>

                            <div className='search'>
                                {/* <span>Search:</span> */}
                                <input
                                    type="text"
                                    placeholder="Search by SKU or Accessory Name"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    className="search-input"
                                />
                                {searchTerm && (
                                    <button onClick={clearSearch} className="clear-button">
                                        &times;
                                    </button>
                                )}
                            </div>

                            {isLoading ? (
                                <div className="loading-overlay">
                                    <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
                                </div>
                            ) : (
                                <>
                                    <div className='table'>
                                        {filteredData?.map((sku, index) => (
                                            <table className="professional-table" >
                                                <thead>
                                                    <tr>
                                                        {headers.map(header => (
                                                            <Tooltip key={header.key} title={`${header.label}`} arrow placement="top">
                                                                <th>{header.label}</th>
                                                            </Tooltip>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr key={index}>
                                                        <td>{sku[0]}</td>
                                                        <td>{sku[1]}</td>
                                                        <td>{sku[2]}</td>
                                                        <td>{sku[3]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManualInputData