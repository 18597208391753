import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { storeNameList } from '../../ReusableFunctions'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Dot,
} from "recharts";
import './ActivationsInsights.scss'
const ActivationsInsights = ({ yearData, type, eventYearData = [] }) => {
    const [barData, setBarData] = useState([]);
    const [topMonth, setTopMonth] = useState("");

    function findKeyByValue(obj, valueToFind) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
                return key;
            }
        }
        return null;
    }

    const emailPrefix = type[0] + '@vyadom.com'

    let ticks = []
    useEffect(() => {
        const getData = async () => {
            try {
                if (yearData.length > 0) {
                    let data = [];
                    data = [...yearData, ...eventYearData]
                    const data1 = [...yearData, ...eventYearData]

                    ticks = [100, 200, 300, 400, 500, 600, 700, 800];
                    if (type[0] && type[1] === undefined) {
                        const filteredData = data1.filter(item => item.user === emailPrefix);
                        data = filteredData
                        ticks = [10, 20, 30, 40, 50, 60, 70, 80];
                    } else if (type[1] && type[0] === '') {
                        const filteredData = data1.filter(item => item.storeInvoice === findKeyByValue(storeNameList, type[1]));
                        data = filteredData
                        ticks = [10, 20, 30, 40, 50, 60, 70, 80];
                    }
                    else if (type[1] && type[0]) {
                        const filteredData = data1.filter(item => item.storeInvoice === findKeyByValue(storeNameList, type[1]) && item.user === emailPrefix);
                        data = filteredData
                        ticks = [10, 20, 30, 40, 50, 60, 70, 80];
                    }
                    const groupedData = data.reduce((acc, item) => {
                        const date = parseISO(item.tenderedDate);
                        const month = format(date, 'MMM'); // Short month name (e.g., Jan, Feb)
                        const year = format(date, 'yyyy'); // Full year

                        const key = `${month} ${year}`;

                        if (!acc[key]) {
                            acc[key] = {
                                month,
                                Postpaid: 0,
                                Prepaid: 0,
                                Hup: 0,
                            };
                        }

                        // Increment counts based on conditions
                        if (item.simNumber !== "N/A" && item.connectionType === "Postpaid") {
                            acc[key].Postpaid++;
                        }
                        if (item.connectionType === "Prepaid") {
                            acc[key].Prepaid++;
                        }
                        if (item.simNumber === "N/A" && item.imei !== "N/A") {
                            acc[key].Hup++;
                        }

                        return acc;
                    }, {});

                    // Convert the grouped data object to an array
                    const result = Object.values(groupedData);

                    setBarData(result);
                    const topMonth = result.reduce((top, current) => {
                        const currentTotal = current.Postpaid + current.Prepaid + current.Hup;
                        const topTotal = top.Postpaid + top.Prepaid + top.Hup;

                        return currentTotal > topTotal ? current : top;
                    });
                    setTopMonth(topMonth)
                }

            } catch (error) {
                return error
            }
        };
        getData();
    }, [yearData, type, eventYearData]);


    const formatLegendValue = (value) => {
        return value.replace("_", " ");
    };

    const formatTooltipValue = (value, name) => {
        return `${value.replace("_", " ")}: ${name}`;
    };

    const CustomTooltipContent = ({ payload }) => {
        if (!payload || !payload.length) return null;

        return (
            <div className="custom-recharts-tooltip">
                <p className="recharts-tooltip-label">{payload[0].payload?.month}</p>
                <ul className="recharts-tooltip-item-list">
                    {payload?.map((payloadItem, index) => {
                        return (
                            <li key={index}>
                                {formatTooltipValue(payloadItem.name, payloadItem.value)}
                            </li>
                        );
                    })}
                    <li></li>
                </ul>
            </div>
        );
    };


    return (
        <div className='block'>
            <div className="block-head">
                <div className="block-title">
                    <span>Activations Insights</span>
                </div>
            </div>
            <div className="line-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={barData}
                        margin={{
                            top: 10,
                            right: 5,
                            left: -20,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid
                            stroke="#f8f8f9"
                            horizontal={true}
                            vertical={false}
                            strokeDasharray="3 0"
                        />
                        <XAxis
                            dataKey="month"
                            tickSize={0}
                            axisLine={false}
                            padding={{ left: 20 }}
                            tick={({ payload, x, y, dy }) => (
                                <text
                                    x={x}
                                    y={y + 20}
                                    dy={dy}
                                    textAnchor="middle"
                                    fill="#7B91B0"
                                    fontSize={14}
                                >
                                    {payload.value}
                                </text>
                            )}
                        />
                        <YAxis
                            tickSize={0}
                            axisLine={false}
                            ticks={ticks}
                            tick={{
                                fill: "#7B91B0",
                                fontSize: 14,
                            }}
                        />
                        <Tooltip content={<CustomTooltipContent />} />
                        <Legend iconType="square" formatter={formatLegendValue} />
                        <ReferenceLine
                            isFront={true}
                            x={topMonth.month}
                            stroke="#F64E60"
                            strokeDasharray="3 3"
                        >
                            <Dot r={5} fill="#F64E60" />
                        </ReferenceLine>
                        <Line
                            dot={false}
                            strokeWidth={4}
                            type="basis"
                            dataKey="Postpaid"
                            stroke="#A700FF"
                        />
                        <Line
                            dot={false}
                            strokeWidth={4}
                            type="basis"
                            dataKey="Prepaid"
                            stroke="#F64E60"
                        />
                        <Line
                            dot={false}
                            strokeWidth={4}
                            type="basis"
                            dataKey="Hup"
                            stroke="#3CD856"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default ActivationsInsights