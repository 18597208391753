import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { getValues } from './ReusableFunctions'

const ReadOnlyUserList = ({ userdata, handleEditEmployee, handleDeleteClick }) => {

    var values = getValues(userdata.stores);
    
    return (
        <tr>
            <td>{userdata.fullname}</td>
            <td>{userdata.username}</td>
            <td>{userdata.password.slice(7, 1)}</td>
            <td>{userdata.email}</td>
            <td>{userdata.role}</td>
            <td>{userdata.phonenumber}</td>
            <td>{userdata.employeeid}</td>
            <td>{values.split(',').map(store => (
                <p>{store}</p>
            ))}</td>
            <td>{userdata.dob}</td>
            <td>
                <IconButton aria-label="delete" size="large" onClick={(event) => handleEditEmployee(event, userdata)}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="delete" size="large" onClick={() => handleDeleteClick(userdata._id)}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </td>
        </tr>
    )
}

export default ReadOnlyUserList