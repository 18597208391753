import React, { useState, useEffect } from 'react'
import './Dashboard.scss'
import { deleteCisDataRow, getUser } from '../../helper/helper';
import { useAuthStore } from '../../store/store';
import { DataGrid } from '@mui/x-data-grid';
import Commissions from '../Commissions';
import DateFilter from '../DateFilter';
import { CSVLink } from "react-csv";
import Modal from '../Modal/Modal';
import ReactLoading from 'react-loading';
import ActivationsInsights from '../Charts/ActivationsInsights/ActivationsInsights'
import Revenues from '../Charts/Revenues/Revenues';
import PieCharts from '../Charts/PieCharts/PieCharts';
import Barcharts from '../Charts/Barcharts/Barcharts';
import { getFilterdByDate } from '../../helper/helper'
import { format, startOfYear, endOfMonth } from 'date-fns';

const Dashboard = () => {

  const { username } = useAuthStore(state => state.auth);
  const [cisdata, setCisdata] = useState([]);
  const [arrIds, setArrIds] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [target, setTarget] = useState([]);
  const [targetRow, setTargetRow] = useState([]);
  const [currentFilter, setCurrentFilter] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeCommissions, setEmployeeCommissions] = useState([]);
  const [typeSelected, setTypeSelected] = useState('')
  const [currentMandY, setCurrentMandY] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accMSRPDataAA, setAccMSRPDataAA] = useState([]);
  const [accMSRPDataOA, setAccMSRPDataOA] = useState([]);
  const [totalAccMSRP, setTotalAccMSRP] = useState(null);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [yearData, setYearData] = useState([])
  const [accListAR, setAccListAR] = useState([])
  const [accListOA, setAccListOA] = useState([])
  const startDate = format(startOfYear(new Date()), 'yyyy-MM-dd');
  const endDate = format(endOfMonth(new Date()), 'yyyy-MM-dd');
  const [loadingRevenue, setLoadingRevenue] = useState(true);

  useEffect(() => {
    const getData = async () => {

      try {
        const { data } = await getFilterdByDate({ startDate, endDate });
        const cisData = data;
        if (cisData.length > 0) {
          let { data } = await getUser({ username: username });
          let finalArray = []

          cisData?.forEach(val => {
            let isExist = data.stores.split(',').some(item => item === val.storeInvoice)

            if (isExist) finalArray.push(val)
          })
          setYearData(finalArray);
          setLoadingRevenue(false);
        }
      } catch (error) {
        return error
      }
    };
    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    updateTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const setLoadingCondition = (condition) => {
    setIsLoading(condition);
  }

  const DateForTarget = async (finalArray) => {
    try {
      let target = []
      let { data } = await getUser({ username: username });
      finalArray?.forEach(val => {
        let isExist = data.stores.split(',').some(item => item === val.storeinvoice)

        if (isExist) target.push(val)
      })
      setTarget(target);
      const storesLists = [];
      for (let stores = 0; stores < target?.length; stores++) {

        const newData = {
          storeinvoice: target[stores].storeinvoice,
          storename: target[stores].storename,
          postpaidtarget: parseInt(target[stores].postpaidtarget),
          prepaidtarget: parseInt(target[stores].prepaidtarget),
          hardwareupgrade: parseInt(target[stores].hardwareupgrade),
          addon: parseInt(target[stores].addon),
          accessories: parseInt(target[stores].accessories),
          applecare: parseInt(target[stores].applecare),
          smssurvey: parseInt(target[stores].smssurvey),

        }
        storesLists.push(newData);
      }
      const resultObject = {};
      storesLists.forEach(item => {
        const { storeinvoice, storename, postpaidtarget, prepaidtarget, hardwareupgrade, addon, accessories, applecare, smssurvey } = item;
        const combinedKey = `${storeinvoice}-${storename}`;
        if (resultObject.hasOwnProperty(combinedKey)) {
          resultObject[combinedKey].postpaidtarget += postpaidtarget;
          resultObject[combinedKey].prepaidtarget += prepaidtarget;
          resultObject[combinedKey].hardwareupgrade += hardwareupgrade;
          resultObject[combinedKey].addon += addon;
          resultObject[combinedKey].accessories += accessories;
          resultObject[combinedKey].applecare += applecare;
          resultObject[combinedKey].smssurvey += smssurvey;

        } else {
          resultObject[combinedKey] = { storeinvoice, storename, postpaidtarget, prepaidtarget, hardwareupgrade, addon, accessories, applecare, smssurvey };

        }
      });

      const resultArray1 = Object.values(resultObject);
      const targetData = [];
      resultArray1.map(item => {

        if (targetData.hasOwnProperty("postpaidtarget")) {
          targetData.postpaidtarget += item.postpaidtarget;
          targetData.prepaidtarget += item.prepaidtarget;
          targetData.hardwareupgrade += item.hardwareupgrade;
          targetData.addon += item.addon;
          targetData.accessories += item.accessories;
          targetData.applecare += item.applecare;
          targetData.smssurvey += item.smssurvey;
        } else {
          targetData.postpaidtarget = item.postpaidtarget;
          targetData.prepaidtarget = item.prepaidtarget;
          targetData.hardwareupgrade = item.hardwareupgrade;
          targetData.addon = item.addon;
          targetData.accessories = item.accessories;
          targetData.applecare = item.applecare;
          targetData.smssurvey = item.smssurvey;
        }
        return targetData;
      })
      setTargetRow(targetData)
    } catch (error) {
      return error;
    }
  }

  const accessoryListAR = (data) => {
    if (data.length > 0) {
      setAccListAR(data);
    }
  }

  const accessoryListOA = (data) => {
    if (data.length > 0) {
      setAccListOA(data);
    }
  }

  const AAMSRP = (data) => {
    setAccMSRPDataAA(data)
    let totalMsrp = 0;

    for (const key in data[1]) {
      if (data[1].hasOwnProperty(key)) {
        totalMsrp += data[1][key].msrp;
      }
    }
    setValue1(totalMsrp)
  }

  const OAMSRP = (data) => {

    setAccMSRPDataOA(data)
    let totalMsrpOA = 0;
    for (const key in data[1]) {
      if (data[1].hasOwnProperty(key)) {
        totalMsrpOA += data[1][key].OAMsrp;
      }
    }
    setValue2(totalMsrpOA)
  }

  const updateTableData = async (data1) => {
    try {
      let finalArray = []
      let { data } = await getUser({ username: username });
      data1?.forEach(val => {
        let isExist = data.stores.split(',').some(item => item === val.storeInvoice)

        if (isExist) finalArray.push(val)
      })

      setCisdata(finalArray)
      setEmployeeData(finalArray)
      setEmployeeCommissions(finalArray);

    } catch (error) {
      return error
    }
  };

  const currentMonthYear = (data) => {
    setCurrentMandY(data);
  }

  const cisAndTargetData = (data) => {
    setEmployeeData(data[0]);
    setEmployeeCommissions(data[0]);
    const targetData = [];
    data[1].map(item => {

      if (targetData.hasOwnProperty("postpaidtarget")) {
        targetData.postpaidtarget += parseInt(item.postpaidtarget);
        targetData.prepaidtarget += parseInt(item.prepaidtarget);
        targetData.hardwareupgrade += parseInt(item.hardwareupgrade);
        targetData.addon += parseInt(item.addon);
        targetData.accessories += parseInt(item.accessories);
        targetData.applecare += parseInt(item.applecare);
        targetData.smssurvey += parseInt(item.smssurvey);
      } else {
        targetData.postpaidtarget = parseInt(item.postpaidtarget);
        targetData.prepaidtarget = parseInt(item.prepaidtarget);
        targetData.hardwareupgrade = parseInt(item.hardwareupgrade);
        targetData.addon = parseInt(item.addon);
        targetData.accessories = parseInt(item.accessories);
        targetData.applecare = parseInt(item.applecare);
        targetData.smssurvey = parseInt(item.smssurvey);
      }
      return targetData;
    })
    setTargetRow(targetData)

    const manualRes = []
    data[2].map(item => {
      if (manualRes.hasOwnProperty('accessories')) {
        manualRes.accessories += parseInt(item.accessories);
        manualRes.applecare += parseInt(item.applecare);
      } else {
        manualRes.accessories = parseInt(item.accessories);
        manualRes.applecare = parseInt(item.applecare);
      }
      return manualRes
    })

  };

  const storeNameList = {
    OSHWTIN: "Lake Vista Plaza",
    LAKESIN: "Lakeshore & Islington",
    VYAD2IN: "Center Mall Barton",
    TCPNFIN: "Town Center Plaza",
    VYACBIN: "Cedarbrae Mall",
    BARRIIN: "Barrie",
    VYAREIN: "Rexdale & Islington",
    DTWICIN: "Briarfield Shopping Centre",
    DAN01IN: "Danforth & Woodbine",
    VYOSHIN: "Big Bay",
    VYAMLIN: "Markham & Lawrence",
    VYAWLIN: "Weston & Lawrence",
    AllStores: 'All Stores'
  }
  function findKeyByValue(obj, valueToFind) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
        return key;
      }
    }
    return null;
  }

  const currentFilteritmes = (data) => {
    setCurrentFilter(data);
    const emailPrefix = data[0] + '@vyadom.com'
    if (data[0] && data[1] === undefined) {
      setTotalAccMSRP((accMSRPDataAA[1][emailPrefix]?.msrp || 0) + ((accMSRPDataOA[0][emailPrefix]?.OAMsrp) ? (accMSRPDataOA[0][emailPrefix]?.OAMsrp) : 0))
    } else if (data[1] && data[0] === '') {
      setTotalAccMSRP((accMSRPDataAA[0][findKeyByValue(storeNameList, data[1])]?.msrp || 0) + ((accMSRPDataOA[1][findKeyByValue(storeNameList, data[1])]?.OAMsrp) ? (accMSRPDataOA[1][findKeyByValue(storeNameList, data[1])]?.OAMsrp) : 0))
    } else if (data[1] && data[0]) {
      setTotalAccMSRP((accMSRPDataAA[2][emailPrefix][findKeyByValue(storeNameList, data[1])]?.msrp || 0) + (accMSRPDataOA[2][findKeyByValue(storeNameList, data[1])][emailPrefix]?.OAMsrp || 0))
    }
  }

  let filterStores = []
  let storesList = cisdata.map(store => store.storeInvoice);

  storesList?.forEach(val => {
    let isExist = filterStores.some(store => store === val);
    if (!isExist) filterStores.push(val);
  })

  let filterEmployee = []
  let employeeList = cisdata?.map(emp => emp.user);
  employeeList?.forEach(val => {
    let isExist = filterEmployee.some(employee => employee === val);
    if (!isExist) filterEmployee.push(val);
  })

  function sumValues(obj1, obj3) {
    let totalMsrp = 0;
    let totalOAMsrp = 0;
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        totalMsrp += obj1[key].msrp;
      }
    }
    for (const key in obj3) {
      if (obj3.hasOwnProperty(key)) {
        totalOAMsrp += obj3[key].OAMsrp;
      }
    }
    return { totalMsrp, totalOAMsrp, grandTotal: totalMsrp + totalOAMsrp };
  }

  const handleTypeChange = (event) => {
    const { value } = event.target;

    setEmployeeCommissions('');

    if (value !== 'Select Type' && value !== 'All') {
      setTypeSelected(value)
      openModal();
    } else {
      setCurrentFilter('');
      setEmployeeCommissions(cisdata)
      setEmployeeData(cisdata);
      setTypeSelected(value)
      const targetData = [];
      target.map(item => {

        if (targetData.hasOwnProperty("postpaidtarget")) {
          targetData.postpaidtarget += parseInt(item.postpaidtarget);
          targetData.prepaidtarget += parseInt(item.prepaidtarget);
          targetData.hardwareupgrade += parseInt(item.hardwareupgrade);
          targetData.addon += parseInt(item.addon);
          targetData.accessories += parseInt(item.accessories);
          targetData.applecare += parseInt(item.applecare);
          targetData.smssurvey += parseInt(item.smssurvey);
        } else {
          targetData.postpaidtarget = parseInt(item.postpaidtarget);
          targetData.prepaidtarget = parseInt(item.prepaidtarget);
          targetData.hardwareupgrade = parseInt(item.hardwareupgrade);
          targetData.addon = parseInt(item.addon);
          targetData.accessories = parseInt(item.accessories);
          targetData.applecare = parseInt(item.applecare);
          targetData.smssurvey = parseInt(item.smssurvey);
        }
        return targetData;
      })
      setTargetRow(targetData)
      const sums = sumValues(accMSRPDataAA[1], accMSRPDataOA[1]);
      setTotalAccMSRP(sums.grandTotal)
    }
  };

  const type = ['Select Type', 'Stores', 'Reps', 'All']

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setTypeSelected('')
  };

  let data = { postCount: 0, preCount: 0, hupCount: 0, autopayCount: 0, withDeviceCount: 0, pppCount: 0, addonsCount: 0 }

  employeeData?.map((item) => {

    if (item.phoneProtectionPlan === "Accepted") {
      data.pppCount++;
    }
    if (item.simNumber !== "N/A" && item.connectionType === "Postpaid") {
      data.postCount++;
    }
    if (item.connectionType === "Prepaid") {
      data.preCount++;
    }
    if (item.simNumber === "N/A" && item.imei !== "N/A") {
      data.hupCount++;
    }
    if (item.autoPayPre === "YES" || item.autoPayPost === "YES" || item.autoPayPre === "successfully" || item.autoPayPre === "successfully for Pre - Authorized Payments" || item.autoPayPost === "successfully for Pre - Authorized Payments") {
      data.autopayCount++;
    }
    if (item.simNumber !== "N/A" && item.imei !== "N/A") {
      data.withDeviceCount++;
    }
    if (item.addons !== "") {
      data.addonsCount++;
    }
    return (data.pppCount, data.postCount, data.preCount, data.hupCount, data.autopayCount, data.withDeviceCount, data.addonsCount);
  })

  function getNumberOfDaysInMonth(monthName, year) {
    // Create a Date object with the first day of the month
    const firstDayOfMonth = new Date(year, new Date(Date.parse(monthName + " 1, " + year)).getMonth(), 1);

    // Move to the next month and then back one day to get the last day of the current month
    const lastDayOfMonth = new Date(year, firstDayOfMonth.getMonth() + 1, 0);

    // Return the day of the month, which represents the number of days in the month
    return lastDayOfMonth.getDate();
  }

  function mtdCalculator(target, achieved) {
    let totatMTD = 0
    const monthName = currentMandY[0];
    const year = currentMandY[1];
    const dateObject = new Date();
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const monthFromCurrentDate = monthNames[dateObject.getMonth()];

    if (target !== 0 && achieved !== 0) {
      if (monthName === monthFromCurrentDate) {
        let currentDate = new Date();
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const remainingDaysCount = lastDayOfMonth.getDate() - currentDate.getDate() + 1;
        let daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        const pastDaysCount = daysInMonth - remainingDaysCount + 1;
        const mtdTarget = (target / daysInMonth) * pastDaysCount;
        totatMTD = parseInt((achieved / mtdTarget * 100).toFixed(2));
        return totatMTD;
      } else if (monthName !== monthFromCurrentDate) {
        const numberOfDays = getNumberOfDaysInMonth(monthName, year);
        const mtdTarget = (target / numberOfDays) * numberOfDays;
        totatMTD = parseInt((achieved / mtdTarget * 100).toFixed(2));
        return totatMTD;
      }
    }
    return totatMTD;
  }

  async function deleteCisData(id) {

    let saleInvoice = null;
    cisdata.forEach(cis => {
      if (cis._id === id[0]) {
        saleInvoice = cis.saleInvoice
      }
    })
    if (window.confirm(`Are you sure you want to delete ${id}'s data?`)) {
      let data = await deleteCisDataRow({ id, saleInvoice });
      if (data) {
        alert("data deleted successfully");
      }
    } else {
      console.log("not done")
    }
  }

  const columns = [
    /* { field: '_id', headerName: 'Id', width: 50 }, */
    { field: 'saleInvoice', headerName: 'Sales Invoice', width: 130 },
    { field: 'tenderedDate', headerName: 'Tendered Date', width: 130 },
    { field: 'customerName', headerName: 'Customer Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'accountNum', headerName: 'Account Number', width: 200 },
    { field: 'deposit', headerName: 'Deposit', width: 100 },
    { field: 'retailPrice', headerName: 'Retail Price', width: 100 },
    { field: 'phoneProtectionPlan', headerName: 'PPP', width: 100 },
    { field: 'activatedPromotion', headerName: 'Promo', width: 400 },
    { field: 'phoneNum', headerName: 'Phone Number', width: 150 },
    { field: 'tempPhoneNum', headerName: 'Temporary Number', width: 150 },
    { field: 'simNumber', headerName: 'Sim Number', width: 200 },
    { field: 'imei', headerName: 'IMEI', width: 200 },
    { field: 'amountYouPaid', headerName: 'Amount Paid', width: 150 },
    { field: 'connectionType', headerName: 'Connection Type', width: 100 },
    { field: 'activationDate', headerName: 'Activation Date', width: 200 },
    { field: 'phoneMake', headerName: 'Phone Make', width: 400 },
    { field: 'planName', headerName: 'Plane Name', width: 400 },
    { field: 'planPrice', headerName: 'Plan Price', width: 100 },
    { field: 'myBalanceOwing', headerName: 'Balance Owing', width: 100 },
    { field: 'oneTimeCharges', headerName: 'One Time Charge', width: 100 },
    { field: 'addons', headerName: 'Add Ones', width: 700 },
    { field: 'autoPayPre', headerName: 'Auto Pay Pre', width: 150 },
    { field: 'autoPayPost', headerName: 'Auto Pay Post', width: 150 },
    { field: 'specialistId', headerName: 'Specialist ID', width: 100 },
    { field: 'storeId', headerName: 'Store ID', width: 100 },
    { field: 'user', headerName: 'User', width: 300 }
  ]

  const rows = employeeData?.map((row) => ({
    _id: row._id,
    saleInvoice: row.saleInvoice,
    tenderedDate: row.tenderedDate,
    customerName: row.customerName,
    email: row.email,
    accountNum: row.accountNum,
    deposit: row.deposit,
    retailPrice: row.retailPrice,
    phoneProtectionPlan: row.phoneProtectionPlan,
    activatedPromotion: row.activatedPromotion,
    phoneNum: row.phoneNum,
    tempPhoneNum: row.tempPhoneNum,
    simNumber: row.simNumber,
    imei: row.imei,
    amountYouPaid: row.amountYouPaid,
    connectionType: row.connectionType,
    activationDate: row.activationDate,
    phoneMake: row.phoneMake,
    planName: row.planName,
    planPrice: row.planPrice,
    myBalanceOwing: row.myBalanceOwing,
    oneTimeCharges: row.oneTimeCharges,
    addons: row.addons,
    autoPayPre: row.autoPayPre,
    autoPayPost: row.autoPayPost,
    specialistId: row.specialistId,
    storeId: row.storeId,
    user: row.user
  }))

  return (
    <div className='managerdashboard'>
      <div className='filter-menu-list'>

        <div className='filter-menu'>
          <DateFilter accessoryListAR={accessoryListAR} accessoryListOA={accessoryListOA} AAMSRP={AAMSRP} OAMSRP={OAMSRP} onDataLoaded={updateTableData} targetData={DateForTarget} /* manualData={manualAcceandAppleCare} */ currentMonth={currentMonthYear} setLoadingCondition={setLoadingCondition} />
        </div>
        <div className='filter-menu'>
          <label >Search By:</label>
          <select name="storeslist" id="storeslist" onChange={handleTypeChange} value={typeSelected}>

            {type.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <Modal
            isOpen={modalOpen}
            onClose={closeModal}
            type={typeSelected}
            data={cisdata}
            filterStores={filterStores}
            filterEmployees={filterEmployee}
            target={target}
            onLoadedData={cisAndTargetData}
            currentFilteritmes={currentFilteritmes}
          />
          {currentFilter[1] && (
            <label className='labelFilter'>Store: {currentFilter[1]}</label>
          )}
          {currentFilter[0] && (
            <label className='labelFilter'>Rep: {currentFilter[0]}</label>
          )}


        </div>

      </div>

      {isLoading ? (
        <div className="loading-overlay">
          <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
        </div>
      ) : (
        <>
          <div className='widgetcontainer-top'>
            <div className='widgetcontainer-top-left'>
              <div className='widgetcontainer-top-left-div'>
                <div className='top-div-p'><p>Total(MTD)</p></div>
                <div className='top-div-span'><span style={{ color: mtdCalculator(targetRow?.postpaidtarget + targetRow?.prepaidtarget, data?.postCount + data.preCount) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(parseInt(targetRow?.postpaidtarget) + parseInt(targetRow?.prepaidtarget), data?.postCount + data.preCount) || 0}%</span></div>
                <div className='top-div-bot'>
                  <div className='target'>
                    <p>Target</p>
                    <span>{targetRow?.postpaidtarget + targetRow?.prepaidtarget || 0}</span>
                  </div>
                  <div className='target'>
                    <p>Achieved</p>
                    <span>{data.postCount + data.preCount}</span>
                  </div>
                </div>
              </div>
              <div className='widgetcontainer-top-left-div'>
                <div className='top-div-p'><p>Postpaid(MTD)</p></div>
                <div className='top-div-span'><span style={{ color: mtdCalculator(targetRow?.postpaidtarget, data?.postCount) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(targetRow?.postpaidtarget, data?.postCount) || 0}%</span></div>
                <div className='top-div-bot'>
                  <div className='target'>
                    <p>Target</p>
                    <span>{targetRow?.postpaidtarget || 0}</span>
                  </div>
                  <div className='target'>
                    <p>Achieved</p>
                    <span>{data.postCount}</span>
                  </div>
                </div>
              </div>
              <div className='widgetcontainer-top-left-div'>
                <div className='top-div-p'><p>Prepaid(MTD)</p></div>
                <div className='top-div-span'><span style={{ color: mtdCalculator(targetRow?.prepaidtarget, data.preCount) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(targetRow?.prepaidtarget, data.preCount) || 0}%</span></div>
                <div className='top-div-bot'>
                  <div className='target'>
                    <p>Target</p>
                    <span>{targetRow?.prepaidtarget || 0}</span>
                  </div>
                  <div className='target'>
                    <p>Achieved</p>
                    <span>{data.preCount}</span>
                  </div>
                </div>
              </div>
              <div className='widgetcontainer-top-left-div'>
                <div className='top-div-p'><p>Hup(MTD)</p></div>
                <div className='top-div-span'><span style={{ color: mtdCalculator(targetRow?.hardwareupgrade, data.hupCount) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}> {mtdCalculator(targetRow?.hardwareupgrade, data.hupCount) || 0}%</span></div>
                <div className='top-div-bot'>
                  <div className='target'>
                    <p>Target</p>
                    <span>{targetRow?.hardwareupgrade || 0}</span>
                  </div>
                  <div className='target'>
                    <p>Achieved</p>
                    <span>{data.hupCount}</span>
                  </div>
                </div>
              </div>
              <div className='widgetcontainer-top-left-div'>
                <div className='top-div-p'><p>Addons(MTD)</p></div>
                <div className='top-div-span'><span style={{ color: mtdCalculator(targetRow?.addon, data.addonsCount) < 100 ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(targetRow?.addon, data.addonsCount) || 0}%</span></div>
                <div className='top-div-bot'>
                  <div className='target'>
                    <p>Target</p>
                    <span>{targetRow?.addon || 0}</span>
                  </div>
                  <div className='target'>
                    <p>Achieved</p>
                    <span>{data.addonsCount}</span>
                  </div>
                </div>
              </div>
              <div className='widgetcontainer-top-left-div'>
                <div className='top-div-p'><p>Accessories(MTD)</p></div>
                <div className='top-div-span'><span style={{ color: (mtdCalculator(targetRow?.accessories, (totalAccMSRP ? totalAccMSRP : (value1 + value2))) < 100) ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{mtdCalculator(targetRow?.accessories, (totalAccMSRP ? totalAccMSRP : (value1 + value2))) || 0}%</span></div>
                <div className='top-div-bot'>
                  <div className='target'>
                    <p>Target</p>
                    <span>${targetRow?.accessories?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) || 0}</span>
                  </div>
                  <div className='target'>
                    <p>Achieved</p>
                    <span>${(totalAccMSRP ? totalAccMSRP : (value1 + value2))?.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) || 0}</span>
                  </div>
                </div>
              </div>

            </div>
            <div className='widgetcontainer-top-right'>
              <div className='top-div-bot'>
                <div className='top-div-bot1'>
                  <div className='top-div-title'><p>Target</p></div>
                  <div className='top-div-body'>
                    <div className='target'>
                      <p>Apple Care</p>
                      <span>{targetRow?.applecare || 0}</span>
                    </div>
                    <div className='target'>
                      <p>SMS Survey</p>
                      <span>{targetRow?.smssurvey || 0}</span>
                    </div>
                  </div>

                </div>
                <div className='top-div-bot2'>
                  <div className='top-div-title'><p>Achieved</p></div>
                  <div className='top-div-body'>
                    <div className='target'>
                      <p>Apple Care</p>
                      <span style={{ color: (targetRow?.applecare !== 0/* manualDataList?.applecare */) ? 'rgb(248, 98, 98)' : 'rgb(41, 214, 70)' }}>{/* manualDataList?.applecare */0 || 0}</span>
                    </div>
                    <div className='target'>
                      <p>AP</p>
                      <span>{data.autopayCount}</span>
                    </div>
                    <div className='target'>
                      <p>W/Device</p>
                      <span>{data.withDeviceCount}</span>
                    </div>
                    <div className='target'>
                      <p>PPP</p>
                      <span>{data.pppCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="charts">
            {loadingRevenue ? (
              <div className="spinner-container">
                <ReactLoading type="bars" color="#09f" height={100} width={100} />
              </div>
            ) : (
              <ActivationsInsights yearData={yearData} type={currentFilter} />
            )}
            {loadingRevenue ? (
              <div className="spinner-container">
                <ReactLoading type="bars" color="#09f" height={100} width={100} />
              </div>
            ) : (
              <Revenues yearData={yearData} type={currentFilter} />
            )}
            <PieCharts cisdata={employeeData} accListAR={accListAR} accListOA={accListOA} type={currentFilter} />
            <Barcharts target={targetRow} achieved={data} />

          </div>
          <div className='bot-widgets'>
            <div className='widgetcontainer'>
              <h3>Commission(Uploaded)</h3>
              <Commissions cisdata={employeeCommissions} targetRow={targetRow} data={data} totalTarget={target} currentFilter={typeSelected} />
            </div>
          </div>

          <div className="tablecontainer" style={{ height: 350, width: '100%' }}>
            <div className="bottomtable">
              <DataGrid
                getRowId={(row) => row._id}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(ids) => {
                  setArrIds(ids)
                }}
              />
            </div>
            <div className="topbuttons">
              <button onClick={() => deleteCisData(arrIds)} className='edit-btn' type='submit'>Delete</button>
              <CSVLink data={employeeData ? employeeData : ''} className='edit-btn'>Download</CSVLink>
            </div>
          </div>
        </>
      )}


    </div>
  )
}

export default Dashboard