import React, { useState } from 'react';
/* import FileUpload from './FileUpload'; */
import './UploadModal.scss';
import toast, { Toaster } from "react-hot-toast";
import { submitHours } from '../../helper/helper'
import * as XLSX from 'xlsx';

const UploadModal = ({ modalIsOpen, closeModal }) => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString);
    const [apidata, setApidata] = useState({})

    if (!modalIsOpen) {
        return null;
    }
    const readHoursFile = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            // Extracting store names
            const stores = excelData.slice(1,);
            fileUploadSucces(stores);
        };
        reader.readAsArrayBuffer(file);

    }
    const fileUploadSucces = async (data) => {
        let mergedData = {};
        let mergedData1 = {};
        data.forEach(item => {
            let key = item[0];
            let key1 = item[1];
            let value = item[2];

            if (!mergedData[key]) {
                mergedData[key] = 0;
            }
            mergedData[key] += value;

            if (!mergedData1[key1]) {
                mergedData1[key1] = 0;
            }
            mergedData1[key1] += value;
        });
        let resultArray = Object.entries(mergedData).map(([key, sum]) => {
            return [key, sum];
        });
        let resultArray1 = Object.entries(mergedData1).map(([key, sum]) => {
            return [key, sum];
        });
        setApidata({
            resultArray, resultArray1, startDate, endDate
        })

    }
    const SubmitHoursData = () => {
        let submitPromise = submitHours({ storeHours: apidata?.resultArray, empHours: apidata?.resultArray1, startDate: apidata?.startDate, endDate: apidata?.endDate });
        toast.promise(submitPromise, {
            loading: 'Uploading...',
            success: <b>Uploaded Successfully...!</b>,
            error: <b>Not uploaded</b>
        }).then(() => {
            closeModal()
        })
    }
    return (
        <div className="eh-modal">
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className="modal-content">
                <span className="close" onClick={closeModal}>
                    &times;
                </span>
                <div className='top'>
                    <div className='filter-menu-list'>
                        <div className='filter-menu'>
                            <div className='filter-datemenu'>
                                <label htmlFor="startDate">From:</label>
                                <input
                                    type="date"
                                    id="startDate"
                                    className='filter-datemenu-input'
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label htmlFor="endDate">To:</label>
                                <input
                                    type="date"
                                    id="endDate"
                                    className='filter-datemenu-input'
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* for Home internet upload */}
                    <div className='block'>
                        <div className="top-block">
                            <h4 >Hours</h4>
                            <span>
                                Select one file at a time.
                            </span>
                        </div>

                        <div className='bottom-block'>
                            <input onChange={readHoursFile} type="file" />
                        </div>
                    </div>

                </div>

                <div className="bottom">
                    <button onClick={SubmitHoursData} className='sub-btn' type='submit'>Upload</button>
                </div>

            </div>

        </div>

    );
};

export default UploadModal;
