import axios from 'axios';
import jwt_decode from 'jwt-decode';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;


/** Make API Requests */


/** To get username from Token */
export async function getUsername() {
    const token = localStorage.getItem('token')
    if (!token) return Promise.reject("Cannot find Token");
    let decode = jwt_decode(token)
    return decode;
}

/** authenticate function */
export async function authenticate(username) {
    try {
        return await axios.post('/api/authenticate', { username })
    } catch (error) {
        return { error: "Username doesn't exist...!" }
    }
}

/** get User details */
export async function getUser({ username }) {
    try {
        const { data } = await axios.get(`/api/user/${username}`);
        return { data }
    } catch (error) {
        return { error: "Password doesn't Match...!" }
    }
}

/*get filterd by date cisdata  */
export async function getFilterdByDate({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/filteredcisdata`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }

}
/*get Event cisdata  */
export async function getEventCisData({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/geteventcisdata`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }

}

/*get filterd Home internet data  */
export async function getHomeInternetData({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/gethomeinternetdata`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }

}

/* get manual input data-accessory and apple care */

export async function getManualInputData({ endDate }) {

    try {
        const { data } = await axios.post(`/api/getmanualinputdata`, {
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/* get employee commission report  */

export async function getEmployeeCommissionReport(endDate) {

    try {
        const { data } = await axios.post(`/api/getemployeecommissionreport`, {
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/* Get Profit loss data */

export async function getPandLData(endDate1) {
    try {
        const { data } = await axios.post(`/api/getpandldata`, {
            endDate1,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}
/*get filterd by date cisdata  */
export async function getFilterRefundByDate({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/filterrefundbydate`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }

}
/*get filterd by date for Attachment rate data */
export async function getAttachRate({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/filterattachrate`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/*get filterd by date for Only Accessory Data */
export async function getOnlyAccessory({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/filteronlyaccessorydata`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/*get filterd by date hours worked data */
export async function getHoursWorkedData({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/filterhoursworkeddata`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/*get event data */
export async function getEvent({ startDate, endDate }) {
    try {
        const { data } = await axios.post(`/api/getevent`, {
            startDate,
            endDate,
        });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/* Get target data by month */
export async function getTargetbyMonth({ selectedMonth, currentYear }) {
    try {
        const { data } = await axios.post(`/api/gettargetbymonth`, { selectedMonth, currentYear });
        return { data }
    } catch (error) {
        return { error: "No Data to be found" }
    }
}

/* get cis data for stores */

export async function getStoresData({ storeInvoice }) {
    try {

        const { data } = await axios.get(`/api/getstores/${storeInvoice}`);
        return { data }

    } catch (error) {
        return { error: "No data found...!" }
    }
}
/* get all cis data */
export async function getAllCisData() {
    try {
        const { data } = await axios.get(`/api/getallcisdata`);
        return { data }
    } catch (error) {
        return { error: "No data found...!" }
    }
}
/* get manual sku list */
export async function getManualSkuList() {
    try {
        const { data } = await axios.get(`/api/getmanualskulist`);
        return { data }
    } catch (error) {
        return { error: "No data found...!" }
    }
}

/* get refund data */

export async function getRefundData() {

    try {
        const { data } = await axios.get(`/api/getrefunddata`);
        return { data };
    } catch (error) {
        return { error: "No data found...!" }
    }
}
/* get all the target data */
export async function getTargets() {
    try {
        const { data } = await axios.get(`/api/gettargets`);
        return { data }
    } catch (error) {
        return { error: "No data found...!" }
    }
}
/* get all users data */

export async function getAllUsersData() {
    try {
        const { data } = await axios.get(`/api/getallusersdata`);
        return { data }
    } catch (error) {
        return { error: "No data found...!" }
    }
}

/* get CIS data */

export async function getCisData({ user }) {

    try {
        const { data } = await axios.get(`/api/getcisdata/${user}`);
        return { data };
    } catch (error) {
        return { error: "No data found...!" }
    }
}

/* Delete cis data */
export async function deleteCisDataRow({ id, saleInvoice }) {
    try {
        const { data: { msg } } = await axios.post(`/api/deletecisdata`, { id, saleInvoice });
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Delete Event cis data */
export async function deleteEventCisDataRow({ id, saleInvoice }) {
    try {
        const { data: { msg } } = await axios.post(`/api/deleteventcisdata`, { id, saleInvoice });
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Delete Event whole data */
export async function deleteEventWholeData({id, name}) {
    try {
        const { data: { msg } } = await axios.post(`/api/deleteventwholedata`, {id, name});
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
export async function deleteManualData({ id }) {
    try {
        const { data: { msg } } = await axios.post(`/api/deletemanualdata`, { id });
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function deleteRefundRow({ id }) {
    try {
        const { data: { msg } } = await axios.post(`/api/deleterefundrow`, { id });
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* delete user data */
export async function deleteUserData({ id }) {
    try {
        const { data: { msg } } = await axios.post(`/api/deleteuser`, { id });
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
/* Delete Target data row */
export async function deleteTargetDataRow({ id }) {
    try {
        const { data: { msg } } = await axios.post(`/api/deletetarget`, { id });
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit cis data */
export async function submitCisData(values) {
    try {
        const { data: { msg } } = await axios.post('/api/home', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit Event cis data */
export async function submitEventCisData(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submiteventcisdata', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit Home Internet data */
export async function submitHomeInternet(values) {
    try {
        const { data: { msg } } = await axios.post('/api/homeinternet', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit accessory attachment rate data */
export async function submitAR(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitar', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit accessory attachment rate data */
export async function submitEventAR(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submiteventar', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit accessory only without act or hup attachment rate data */
export async function submitAROnlyAccessory(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitaronlyaccessory', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit Hours data */
export async function submitHours(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submithours', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Post filtered data from score card processing */

export async function filteredCommissionAfterScorecard(values) {
    try {
        const { data: { msg } } = await axios.post('/api/filteredcommissiondata', values)
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* Submit P/L  */


export async function submitPandLData(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitpandldata', values)
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
/* Submit Accessories and apple care data */

export async function submitManualInputData(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitmanualinputdata', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* submit refund data */
export async function submitRefundData(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitrefunddata', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
/* submit manual sku list */

export async function sumbitManualSkuList(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitmanualskulist', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
/* submit scorecard data */
export async function submitScoreCardData(values) {
    try {
        const { data: { msg } } = await axios.post('/api/admin', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}

/* submit Event data */
export async function submitEvent(values) {
    try {
        const { data: { msg } } = await axios.post('/api/submitevent', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
/* set targets for employee */
export async function setTargets(values) {
    try {
        const { data: { msg } } = await axios.post('/api/settargets', values);
        return Promise.resolve(msg);
    } catch (error) {
        return Promise.reject(error);
    }
}
/** register user function */
export async function registerUser(credentials) {
    try {
        const { data: { msg }, status } = await axios.post(`/api/register`, credentials);
        let { fullname, username, password, email } = credentials;
        /** send email */
        if (status === 201) {
            await axios.post('/api/registerMail', { fullname: fullname, username: username, password: password, userEmail: email })
        }
        return Promise.resolve(msg)
    } catch (error) {
        return Promise.reject({ error })
    }
}

/** login function */
export async function verifyPassword({ username, password }) {
    try {
        if (username) {
            const { data } = await axios.post('/api/login', { username, password })
            return Promise.resolve({ data });
        }
    } catch (error) {
        return Promise.reject({ error: "Username or Password doesn't Match...!" })
    }
}

/** update user profile function */
export async function updateUserProfilePicture({ profilePicture, username }) {
    try {

        const { data, status } = await axios.put('/api/updateuserprofilepicture', { profilePicture, username });

        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error: "Couldn't Update Profile...!" })
    }
}
/** update user firstlogin status function */
export async function updateFirstLoginStatus({ username }) {
    try {

        const { data, status } = await axios.put('/api/updatefirstloginstatus', { username });

        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error: "Couldn't Update Profile...!" })
    }
}

/** generate OTP */
export async function generateOTP(username) {
    try {
        const { data: { code }, status } = await axios.get('/api/generateOTP', { params: { username } });

        // send mail with the OTP
        if (status === 201) {
            let { data: { email } } = await getUser({ username });
            let text = `Your Password Recovery OTP is ${code}. Verify and recover your password.`;
            await axios.post('/api/registerMail', { username, userEmail: email, text, subject: "Password Recovery OTP" })
        }
        return Promise.resolve(code);
    } catch (error) {
        return Promise.reject({ error });
    }
}

/** verify OTP */
export async function verifyOTP({ username, code }) {
    try {
        const { data, status } = await axios.get('/api/verifyOTP', { params: { username, code } })
        return { data, status }
    } catch (error) {
        return Promise.reject(error);
    }
}

/** reset password */
export async function resetPassword({ username, password }) {
    try {
        const { data, status } = await axios.put('/api/resetPassword', { username, password });
        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error })
    }
}

/* edit password in dashboard */
export async function editPassword({ username, oldPassword, newPassword }) {
    try {
        const { data, status } = await axios.put('/api/editpassword', { username, oldPassword, newPassword });
        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error })
    }

}

/* edit data in UserList */
export async function editUserListData({ _id, fullname, username, password, email, role, phonenumber, employeeid, stores, dob }) {
    try {
        const { data, status } = await axios.put('/api/edituserlistdata', { _id, fullname, username, password, email, role, phonenumber, employeeid, stores, dob });

        /** send email */
        if (status === 201) {
            await axios.post('/api/registerMail', { username: username, userEmail: email, password: password, fullname: fullname, text: "User Profile Updated" });
        }
        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error })
    }

}
/* edit target row */
export async function editTargetRow({ _id, username, storename, storeinvoice, employeename, prepaidtarget, postpaidtarget, hardwareupgrade, addon, accessories, applecare, smssurvey }) {
    try {
        const { data, status } = await axios.put('/api/edittarget', { _id, username, storename, storeinvoice, employeename, prepaidtarget, postpaidtarget, hardwareupgrade, addon, accessories, applecare, smssurvey });
        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error })
    }
}

/* edit manual data-accessories and apple care */

export async function editManualData({ _id, accessories, applecare }) {
    try {
        const { data, status } = await axios.put('/api/editmanual', { _id, accessories, applecare });
        return Promise.resolve({ data, status })
    } catch (error) {
        return Promise.reject({ error })
    }
}