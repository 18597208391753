import React, { useState, useEffect } from 'react'
import './PieCharts.scss'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { storeNameList } from '../../ReusableFunctions'
import * as XLSX from 'xlsx';

const PieCharts = ({ cisdata, accListAR, accListOA, type, eventCisData = [] }) => {
    const values = ['Prepaid Plan', 'Postpaid Plan', 'Phone', 'Accessory']
    const [PieChartData, setPieChartData] = useState([])
    const [selectedValue, setSelectedValue] = useState(values[0]);
    function findKeyByValue(obj, valueToFind) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === valueToFind) {
                return key;
            }
        }
        return null;
    }

    const emailPrefix = type[0] + '@vyadom.com'
    let data = [];
    let data1 = [];
    data = accListAR
    data1 = accListOA
    if (type[0] && type[1] === undefined) {
        const filteredData = accListAR.filter(item => item.user === emailPrefix);
        const filteredData1 = accListOA.filter(item => item.username === emailPrefix);
        data = filteredData
        data1 = filteredData1
    } else if (type[1] && type[0] === '') {
        const filteredData = accListAR.filter(item => item.storeInvoice === findKeyByValue(storeNameList, type[1]));
        const filteredData1 = accListOA.filter(item => item.storeInvoiceOA === findKeyByValue(storeNameList, type[1]));
        data = filteredData
        data1 = filteredData1
    }
    else if (type[1] && type[0]) {
        const filteredData = accListAR.filter(item => item.storeInvoice === findKeyByValue(storeNameList, type[1]) && item.user === emailPrefix);
        const filteredData1 = accListOA.filter(item => item.storeInvoiceOA === findKeyByValue(storeNameList, type[1]) && item.username === emailPrefix);
        data = filteredData
        data1 = filteredData1
    }

    const combinedArray = [];

    // Extracting from accListAR
    data.forEach(item => {
        if (item.attachedAcc && item.attachedAcc.length > 0) {
            combinedArray.push(...item.attachedAcc);
        }
    });

    // Extracting from accListOA
    data1.forEach(item => {
        if (item.attachedAccOnlyAccessory && item.attachedAccOnlyAccessory.length > 0) {
            combinedArray.push(...item.attachedAccOnlyAccessory);
        }
    });

    // Count frequency of accessory names
    const frequencyMap = {};
    combinedArray.forEach(accessory => {
        const accessoryName = accessory[1];
        if (frequencyMap[accessoryName]) {
            frequencyMap[accessoryName]++;
        } else {
            frequencyMap[accessoryName] = 1;
        }
    });

    // Convert frequency map to array and sort by frequency
    const sortedAccessories = Object.entries(frequencyMap)
        .sort((a, b) => b[1] - a[1])
        .map(entry => ({ name: entry[0], value: entry[1] }));

    // Slice the top 4 accessories

    const top4Accessories = sortedAccessories.slice(0, 5);


    const combinedCISArray = [...cisdata, ...eventCisData]

    const counts = combinedCISArray?.reduce((acc, obj) => {
        const planName = obj.planName;
        const phoneMake = obj.phoneMake;
        const type = obj.connectionType;
        const sim = obj.simNumber;
        if (type === "Prepaid") {
            acc.prepaidCounts[planName] = (acc.prepaidCounts[planName] || 0) + 1;
        } else if (type === "Postpaid" && sim !== "") {
            acc.postpaidCounts[planName] = (acc.postpaidCounts[planName] || 0) + 1;
        }
        if (phoneMake !== "Bring Your Own Phone") {
            acc.phoneCounts[phoneMake] = (acc.phoneCounts[phoneMake] || 0) + 1;
        }
        return acc;
    }, { prepaidCounts: {}, postpaidCounts: {}, phoneCounts: {} });
    const sortedPrepaid1 = Object.entries(counts.prepaidCounts)
        .sort((a, b) => b[1] - a[1])
        .map(entry => ({ name: entry[0], value: entry[1] }))
    const sortedPrepaid = sortedPrepaid1.slice(0, 5);
    const sortedPostpaid1 = Object.entries(counts.postpaidCounts)
        .sort((a, b) => b[1] - a[1])
        .map(entry => ({ name: entry[0], value: entry[1] }))
    const sortedPostpaid = sortedPostpaid1.slice(0, 5);

    const sortedPhones1 = Object.entries(counts.phoneCounts)
        .sort((a, b) => b[1] - a[1])
        .map(entry => ({ name: entry[0], value: entry[1] }))
    const sortedPhones = sortedPhones1.slice(0, 5);

    useEffect(() => {
        setPieChartData(sortedPrepaid)
        setSelectedValue(values[0]);
    }, [cisdata, type])

    const HandleInputChange = (e) => {
        e.preventDefault();
        const { value } = e.target
        setSelectedValue(value);
        if (value === "Prepaid Plan") {
            setPieChartData(sortedPrepaid)
        } else if (value === "Postpaid Plan") {
            setPieChartData(sortedPostpaid)
        } else if (value === "Phone") {
            setPieChartData(sortedPhones)
        } else if (value === "Accessory") {
            setPieChartData(top4Accessories)
        }
    }

    /* const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']; */
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A020F0'];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent/* , index */ }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const formatLegendValue = (value) => {

        return value.charAt(0).toUpperCase() + value.slice(1)/*  + " Sales" */;
    };

    const handleDownload = () => {
        const addRanking = (data) => data.map((item, index) => ({ Ranking: index + 1, ...item }));

        // Add ranking numbers to both datasets
        const rankedData1 = addRanking(sortedPrepaid1);
        const rankedData2 = addRanking(sortedPostpaid1);
        const rankedData3 = addRanking(sortedPhones1);
        const rankedData4 = addRanking(sortedAccessories);

        // Convert JSON data to worksheet
        const wsPrepaid = XLSX.utils.json_to_sheet(rankedData1, { header: ["Ranking", "Product Name", "Count"] });
        const wsPostpaid = XLSX.utils.json_to_sheet(rankedData2, { header: ["Ranking", "Product Name", "Count"] });
        const wsPhones = XLSX.utils.json_to_sheet(rankedData3, { header: ["Ranking", "Product Name", "Count"] });
        const wsAccessories = XLSX.utils.json_to_sheet(rankedData4, { header: ["Ranking", "Product Name", "Count"] });

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Append worksheets to the workbook
        XLSX.utils.book_append_sheet(wb, wsPrepaid, "Prepaid");
        XLSX.utils.book_append_sheet(wb, wsPostpaid, "Postpaid");
        XLSX.utils.book_append_sheet(wb, wsPhones, "Phones");
        XLSX.utils.book_append_sheet(wb, wsAccessories, "Accessories");

        // Write the workbook and trigger a download
        XLSX.writeFile(wb, 'Top Seller_Vflow.xlsx');
    }

    return (
        <div className='block'>
            <div className="block-head">
                <div className="block-title">
                    <span>Top Sellers</span>
                </div>
                <div className='block-select'>
                    <select value={selectedValue} onChange={HandleInputChange}>
                        {values.map((name, index) => (
                            <option key={index} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='block-export-button'>
                    <button className='edit-btn' onClick={handleDownload}>Export</button>
                </div>
            </div>
            <div className="bar-chart">
                <ResponsiveContainer width="50%" height="100%">
                    <PieChart width={350} height={350}>
                        <Pie
                            data={PieChartData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {PieChartData?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        {/* <Legend
                            iconType="circle"
                            iconSize={10}
                            formatter={formatLegendValue}
                            style={{
                                paddingTop: "10px",
                            }}
                            align="left"
                        /> */}
                    </PieChart>
                </ResponsiveContainer>
                <div className="label">
                    <span>Product Name</span>
                    <ul>
                        {PieChartData.map((entry, index) => (
                            <li key={`label-${index}`}>
                                <span
                                    className="bullet"
                                    style={{ backgroundColor: COLORS[index % COLORS.length] }}
                                ></span>
                                {entry.name}{/* : {entry.value} */}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default PieCharts