import React, { useState, useEffect } from 'react'
import './AttachRate.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import ReactLoading from 'react-loading';
import { getAttachRate, getAllUsersData, getUser, getFilterdByDate, getOnlyAccessory, getHoursWorkedData } from '../../helper/helper'
import { useAuthStore } from '../../store/store';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Sidebar from '../../components/Sidebar/Sidebar'
import { cisCommisson, calculateStoreCommissionAfterCisCommission } from '../../components/ReusableFunctions'


const AttachRate = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString);
    const [aRArray, setARArray] = useState([])
    const [aRArray1, setARArray1] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [userDetail, setUserDetail] = useState(null);
    const [userdata, setUserdata] = useState([]);
    const [filterEmp, setFilterEmp] = useState('');
    const [storeAttachData, setStoreAttachData] = useState(null)
    const [storesList, setStoresList] = useState([])
    const [finalARData, setFinalARData] = useState([]);
    const [userList, setuserList] = useState([])
    /* const [seconds, setSeconds] = useState(5); */
    const [cisdata, setCisdata] = useState([]);
    const [Rev, setRev] = useState(null);
    const [OnlyAccData, setOnlyAccData] = useState([])
    const [OAfiltered, setOAfiltered] = useState({})
    const [hoursAPI, setHoursAPI] = useState([])

    const { username } = useAuthStore(state => state.auth);

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getUser({ username: username });
            setUserDetail(data)
        }
        fetchUser();
    }, [username])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getAllUsersData();
            setuserList(data)
        }
        fetchUser();
    }, [username])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getFilterdByDate({ startDate, endDate });
            setCisdata(data)
        }
        fetchUser();
    }, [startDate, endDate])

    useEffect(() => {
        async function fetchUser() {
            let { data } = await getOnlyAccessory({ startDate, endDate });
            let filtered = [];
            data.forEach(obj => {
                filtered.push(obj.attachmentRateOnlyAccessory)
            })
            setOnlyAccData(filtered)
        }
        fetchUser();
    }, [startDate, endDate])

    useEffect(() => {
        async function fetchUser() {
            const { data } = await getHoursWorkedData({ startDate, endDate });
            setHoursAPI(data)
        }
        fetchUser();
    }, [startDate, endDate])

    /* useEffect(() => {
        const timerId = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds > 0) {
                    return prevSeconds - 1;
                } else {
                    clearInterval(timerId);
                    setIsLoading(false); // Set isLoading to false when timer reaches 0
                    return 0;
                }
            });
        }, 1000);

        return () => clearInterval(timerId); // Clean up interval on component unmount
    }, []); */

    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton" || "Center Mall at Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
    };
    const storeNameList2 = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall at Barton",
        TCPNFIN: "Town Centre Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
    };

    const capitalizeFirstLetter = (string) => {
        if (string.length === 7) {
            return storeNameList[string]
        } else {
            let user = []
            for (let key in userdata) {
                if (userdata[key].username === string) {
                    user.push(userdata[key].fullname)
                }
            }
            return user.length > 0 ? user[0] : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().slice(0, -11)
        }

    };

    function attachrateCalculator(groupedBySpecialist) {
        const groupedBySpecialistmerged = {};
        for (let key in groupedBySpecialist) {
            let hupCount = 0;
            let hupSum = { cost: 0, msrp: 0, discount: 0 };
            let actCount = 0;
            let actSum = { cost: 0, msrp: 0, discount: 0 };
            let actWithoutAcc = 0;
            let hupWithoutAcc = 0;
            let accActNumber = 0;
            let accHupNumber = 0;
            let actPPP = 0;
            let hupPPP = 0;
            let actWDP = 0;
            groupedBySpecialist[key].forEach(obj => {
                const { aaData, connectionType, simNumber, attachedAcc, phoneProtectionPlan, imei } = obj;
                const { cost, msrp, discount } = aaData;
                let accNo = attachedAcc.length

                if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A")) {
                    actWithoutAcc++;
                } else if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A")) {
                    hupWithoutAcc++;
                } else {
                    if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A") {
                        hupCount++;
                        hupSum.cost += cost;
                        hupSum.msrp += msrp;
                        hupSum.discount += discount;
                        accHupNumber += accNo
                        if (phoneProtectionPlan === "Accepted") {
                            hupPPP++
                        }
                    } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A") {
                        actCount++;
                        actSum.cost += cost;
                        actSum.msrp += msrp;
                        actSum.discount += discount;
                        accActNumber += accNo
                        if (phoneProtectionPlan === "Accepted") {
                            actPPP++
                        }
                        if (imei !== "N/A") {
                            actWDP++
                        }
                    }
                }
            });

            let bestMatch = null;
            hoursAPI[0].storeHours.forEach(item => {
                const name = item[0];
                if (storeNameList2[key] === name) {
                    bestMatch = item[1];
                }
            });

            groupedBySpecialistmerged[key] = {
                act: actWithoutAcc + actCount,
                actWithoutAcc,
                actCount,
                hup: hupCount + hupWithoutAcc,
                hupCount,
                hupWithoutAcc,
                actSf: actSum.msrp - actSum.discount,
                actSum,
                hupSum,
                hupSf: hupSum.msrp - hupSum.discount,
                actPm: (actSum.msrp - actSum.discount) - actSum.cost,
                hupPm: (hupSum.msrp - hupSum.discount) - hupSum.cost,
                actARate: actCount / (actWithoutAcc + actCount) * 100,
                hupARate: hupCount / (hupCount + hupWithoutAcc) * 100,
                accHupNumber,
                accActNumber,
                accActNumRate: (accActNumber / (actWithoutAcc + actCount)),
                accHupNumRate: (accHupNumber / (hupCount + hupWithoutAcc)),
                actPPP: actPPP,
                hupPPP: hupPPP,
                actPPPRate: actPPP / (actWithoutAcc + actCount) * 100,
                hupPPPRate: hupPPP / (hupCount + hupWithoutAcc) * 100,
                actWD: actWDP,
                actWDRate: actWDP / (actWithoutAcc + actCount) * 100,
                Comm: Rev[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount),
                hours: bestMatch,
                actperH: bestMatch / (actWithoutAcc + actCount + hupCount + hupWithoutAcc),
                revperH: (Rev[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount)) / bestMatch,
            }
        }
        return groupedBySpecialistmerged
    }

    const handleSearch = async () => {
        setIsLoading(true);
        const { data } = await getAttachRate({ startDate, endDate });

        const removeDuplicatesByInvoice = (array) => {
            const seenInvoices = new Set();
            return array.filter(item => {
                if (!item.saleInvoice) {
                    return true; // Keep objects without saleInvoice
                }
                const duplicate = seenInvoices.has(item.saleInvoice);
                seenInvoices.add(item.saleInvoice);
                return !duplicate;
            });
        };
        const uniqueArray = removeDuplicatesByInvoice(data);

        if (userDetail.role === 'manager') {
            let finalArray = []
            userDetail?.stores?.split(',').forEach(store => {
                userList.forEach(user => {
                    let exist = user.stores.split(',').some(item => item === store)
                    if (exist /* && user.role !== 'manager' */) {
                        if (!finalArray.includes(user)) {
                            finalArray.push(user)
                        }
                    }
                })
            })
            setUserdata(finalArray);
            let storesList = userDetail?.stores?.split(',')
            setStoresList(storesList)
            let employeeList = finalArray?.map(emp => emp.username);
            setFilterEmp(employeeList);

            let filterAR = []
            storesList.forEach(store => {
                uniqueArray.forEach(obj => {
                    const { storeInvoice } = obj;
                    if (storeInvoice === store) {
                        filterAR.push(obj)
                    }
                })
            })
            let matchingInvoices = OnlyAccData.filter(item => {
                // Extract the prefix part of the saleInvoiceOA
                let prefix = item.saleInvoiceOA.match(/^[a-zA-Z]+/)[0];
                // Check if the extracted prefix matches any prefix in array2
                return storesList.some(prefixInArray => prefix === prefixInArray);
            }).map(item => item);
            setFinalARData(filterAR)
            setOnlyAccData(matchingInvoices)
        } else if (userDetail.role === 'admin') {
            setUserdata(uniqueArray);
            let filterStores = []
            let storesList = uniqueArray?.map(store => store.storeInvoice);
            storesList?.forEach(val => {
                let isExist = filterStores.some(store => store === val);
                if (!isExist) filterStores.push(val);
            })
            setStoresList(filterStores)
            let filterEmployee = []
            let employeeList = uniqueArray?.map(emp => emp.user);
            employeeList?.forEach(val => {
                let isExist = filterEmployee.some(employee => employee === val);
                if (!isExist) filterEmployee.push(val);
            })
            setFilterEmp(filterEmployee);
            setFinalARData(uniqueArray)
        } else if (userDetail.role === 'employee') {
            let filterAR = []
            uniqueArray.forEach(obj => {
                if (obj.user === userDetail.username) {
                    filterAR.push(obj)
                }
            })

            let storesList = userDetail?.stores?.split(',')
            let matchingInvoices = []
            OnlyAccData.forEach(item => {
                if (item.username === userDetail.username) {
                    matchingInvoices.push(item)
                }
            })
            setFinalARData(filterAR)
            setStoresList(storesList)
            setOnlyAccData(matchingInvoices)
        }
    }

    if ((isLoading && storesList?.length > 0) && cisdata?.length > 0 /* && OnlyAccData?.length > 0 *//* || seconds < 1 */) {

        setIsLoading(false);
        if (finalARData?.length > 0) {

            const groupedBySpecialist = {};
            const groupedByStore = {};
            let oAEmpList = {};
            let oAStoreList = {};

            function groupByProperty(data, property) {
                return data.reduce((acc, obj) => {
                    const key = obj[property];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
            }
            const groupedByUser = groupByProperty(cisdata, 'user');
            const groupedByStoreInvoice = groupByProperty(cisdata, 'storeInvoice');

            finalARData.forEach(obj => {
                const { user, ...rest } = obj;
                if (!groupedBySpecialist[user]) {
                    groupedBySpecialist[user] = [rest];
                } else {
                    groupedBySpecialist[user].push(rest);
                }
                const { storeInvoice, ...rest1 } = obj;
                if (!groupedByStore[storeInvoice]) {
                    groupedByStore[storeInvoice] = [rest1];
                } else {
                    groupedByStore[storeInvoice].push(rest1);
                }
            });

            OnlyAccData.forEach(obj => {
                const { username, ...rest } = obj;

                if (!oAEmpList[username]) {
                    oAEmpList[username] = [rest]
                } else {
                    oAEmpList[username].push(rest)
                }
            })

            const defaultValue = {
                aaDataOnlyAccessory: { cost: 0, msrp: 0, discount: 0 },
                attachedAccOnlyAccessory: [],
                saleInvoiceOA: "",
                salesPerson: "",
                storeInvoiceOA: "",
                tenderedDateOA: "",
                timeOA: ""
            };
            userList.forEach(user => {
                if (!oAEmpList[user.username]) {
                    oAEmpList[user.username] = [defaultValue];
                }
            });

            OnlyAccData.forEach(obj => {
                const { saleInvoiceOA, ...rest } = obj;
                const storeInvoice = saleInvoiceOA.slice(0, 7);
                if (!oAStoreList[storeInvoice]) {
                    oAStoreList[storeInvoice] = [rest]
                } else {
                    oAStoreList[storeInvoice].push(rest)
                }
            })

            setOAfiltered(oAStoreList)
            setStoreAttachData(groupedByStore)
            let emplevelCommission = {}
            let storelevelCommission = {}

            for (let key in groupedByUser) {
                let computedCustomerList = cisCommisson(groupedByUser[key]);
                let finalComm = calculateStoreCommissionAfterCisCommission(computedCustomerList)
                let { postpaidCommission, prepaidCommission, hupCommission, hupMargin, addonCommission, deposit } = finalComm
                emplevelCommission[key] = addonCommission + hupCommission + hupMargin + postpaidCommission + prepaidCommission - deposit
            }
            for (let key in groupedByStoreInvoice) {
                let computedCustomerList = cisCommisson(groupedByStoreInvoice[key]);
                let finalComm = calculateStoreCommissionAfterCisCommission(computedCustomerList)
                let { addonCommission, hupCommission, hupMargin, postpaidCommission, prepaidCommission, deposit } = finalComm
                storelevelCommission[key] = addonCommission + hupCommission + hupMargin + postpaidCommission + prepaidCommission - deposit
            }
            setRev(storelevelCommission)
            console.log(groupedBySpecialist)
            const groupedBySpecialistmerged = {};
            for (let key in groupedBySpecialist) {
                let hupCount = 0;
                let hupSum = { cost: 0, msrp: 0, discount: 0 };
                let actCount = 0;
                let actSum = { cost: 0, msrp: 0, discount: 0 };
                let actWithoutAcc = 0;
                let hupWithoutAcc = 0;
                let accActNumber = 0;
                let accHupNumber = 0;
                let actPPP = 0;
                let hupPPP = 0;
                let actWDP = 0;


                groupedBySpecialist[key].forEach(obj => {
                    const { aaData, connectionType, simNumber, attachedAcc, phoneProtectionPlan, imei } = obj;
                    const { cost, msrp, discount } = aaData;
                    let accNo = attachedAcc.length

                    if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A")) {
                        actWithoutAcc++;
                    } else if (cost === 0 && msrp === 0 && discount === 0 && ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A")) {
                        hupWithoutAcc++;
                    } else {
                        if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A") {
                            hupCount++;
                            hupSum.cost += cost;
                            hupSum.msrp += msrp;
                            hupSum.discount += discount;
                            accHupNumber += accNo
                        } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A") {
                            actCount++;
                            actSum.cost += cost;
                            actSum.msrp += msrp;
                            actSum.discount += discount;
                            accActNumber += accNo
                        }
                    }
                    if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber === "N/A" && phoneProtectionPlan === "Accepted") {
                        hupPPP++
                    } else if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A" && phoneProtectionPlan === "Accepted") {
                        actPPP++
                    }
                    if ((connectionType === "Postpaid" || connectionType === "Prepaid") && simNumber !== "N/A" && imei !== "N/A") {
                        actWDP++
                    }
                });

                const emailPrefix = key.split('@')[0].toLowerCase();
                const matchScore = (emailPrefix, name) => {
                    let score = 0;
                    let emailIndex = 0;
                    for (let i = 0; i < name.length; i++) {
                        if (name[i].toLowerCase() === emailPrefix[emailIndex]) {
                            emailIndex++;
                            score += 10; // Add a base score for each sequential match
                        } else {
                            score -= 1; // Penalize for non-matching characters
                        }
                        if (emailIndex === emailPrefix.length) {
                            // Bonus for matching the entire prefix early
                            score += 50 - i; // Higher score for matches that occur earlier
                            break;
                        }
                    }
                    return score;
                };
                // Find the best match
                let bestMatch = null;
                let bestScore = -Infinity;

                hoursAPI[0].empHours.forEach(item => {
                    const [name] = item;
                    const score = matchScore(emailPrefix, name);

                    if (score > bestScore) {
                        bestScore = score;
                        bestMatch = item;
                    }
                });
                // Result
                const result = bestMatch ? bestMatch[1] : null;

                groupedBySpecialistmerged[key] = {
                    act: actWithoutAcc + actCount,
                    actWithoutAcc,
                    actCount,
                    hup: hupCount + hupWithoutAcc,
                    hupCount,
                    hupWithoutAcc,
                    actSf: actSum.msrp - actSum.discount,
                    actSum,
                    hupSum,
                    hupSf: hupSum.msrp - hupSum.discount,
                    actPm: (actSum.msrp - actSum.discount) - actSum.cost,
                    hupPm: (hupSum.msrp - hupSum.discount) - hupSum.cost,
                    actARate: actCount / (actWithoutAcc + actCount) * 100,
                    hupARate: hupCount / (hupCount + hupWithoutAcc) * 100,
                    accHupNumber,
                    accActNumber,
                    accActNumRate: (accActNumber / (actWithoutAcc + actCount)),
                    accHupNumRate: (accHupNumber / (hupCount + hupWithoutAcc)),
                    actPPP: actPPP,
                    hupPPP: hupPPP,
                    actPPPRate: actPPP / (actWithoutAcc + actCount) * 100,
                    hupPPPRate: hupPPP / (hupCount + hupWithoutAcc) * 100,
                    actWD: actWDP,
                    actWDRate: actWDP / (actWithoutAcc + actCount) * 100,
                    Comm: emplevelCommission[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount),
                    hours: result,
                    actperH: result / (actWithoutAcc + actCount + hupCount + hupWithoutAcc),
                    revperH: (emplevelCommission[key] + (actSum.msrp - actSum.discount) + (hupSum.msrp - hupSum.discount)) / result
                }
            }
            const groupedByAccmerged = {};
            console.log(oAEmpList)
            for (let key in oAEmpList) {

                let accSum = { cost: 0, msrp: 0, discount: 0 };
                let OAtotal = 0;
                oAEmpList[key].forEach(obj => {
                    const { aaDataOnlyAccessory, attachedAccOnlyAccessory, saleInvoiceOA, storeInvoiceOA } = obj;
                    const { cost: costOA, msrp: msrpOA, discount: discountOA } = aaDataOnlyAccessory;
                    let OANumber = attachedAccOnlyAccessory.length
                    accSum.cost += costOA
                    accSum.msrp += msrpOA
                    accSum.discount += discountOA
                    OAtotal += OANumber
                })
                groupedByAccmerged[key] = {
                    OACost: accSum.cost,
                    OAMsrp: accSum.msrp,
                    OADiscount: accSum.discount,
                    OASF: accSum.msrp - accSum.discount,
                    OAPM: (accSum.msrp - accSum.discount) - accSum.cost,
                    OAtotal
                }
            }
            const updatedObj1 = { ...groupedBySpecialistmerged };
            Object.keys(groupedByAccmerged).forEach(key => {
                if (updatedObj1[key]) {
                    // If key exists in obj1, add properties from ob2
                    updatedObj1[key] = { ...updatedObj1[key], ...groupedByAccmerged[key] };
                    updatedObj1[key] = {
                        ...updatedObj1[key],
                        OAplusComm: updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0),
                        OAPlusrevperH: (updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0)) / updatedObj1[key].hours
                    }
                }
            });
            const entries = Object.entries(updatedObj1);
            entries.sort((a, b) => b[1].OAPlusrevperH - a[1].OAPlusrevperH);
            const sortedData = Object.fromEntries(entries);

            setARArray(sortedData);
            setARArray1(sortedData);
        }
    }

    const handleEmployeeNameChange = (e) => {
        e.preventDefault();
        const { value } = e.target
        if (value !== "") {
            const obj = {};
            Object.keys(aRArray).map(name => {
                if (capitalizeFirstLetter(name) === capitalizeFirstLetter(value)) {
                    obj[name] = aRArray[name]
                }
            })
            setARArray1(obj);
            let cisEmpList = [];
            cisdata.forEach(cis => {
                if (cis.user === value) {
                    cisEmpList.push(cis);
                }
            })
        } else {
            setARArray1(aRArray);
        }
    }
    const handleStoreNameChange = (e) => {
        e.preventDefault();
        const { value } = e.target;
        let data = attachrateCalculator(storeAttachData)
        const groupedByAccmerged = {};
        for (let key in OAfiltered) {

            let accSum = { cost: 0, msrp: 0, discount: 0 };
            let OAtotal = 0;
            OAfiltered[key].forEach(obj => {
                const { aaDataOnlyAccessory, attachedAccOnlyAccessory, saleInvoiceOA, storeInvoiceOA } = obj;
                const { cost: costOA, msrp: msrpOA, discount: discountOA } = aaDataOnlyAccessory;
                let OANumber = attachedAccOnlyAccessory.length
                accSum.cost += costOA
                accSum.msrp += msrpOA
                accSum.discount += discountOA
                OAtotal += OANumber
            })
            groupedByAccmerged[key] = {
                OACost: accSum.cost,
                OAMsrp: accSum.msrp,
                OADiscount: accSum.discount,
                OASF: accSum.msrp - accSum.discount,
                OAPM: (accSum.msrp - accSum.discount) - accSum.cost,
                OAtotal
            }
        }
        if (value !== "") {
            const obj = {};
            const obj2 = {};
            Object.keys(data).map(name => {
                if (name === value) {
                    obj[name] = data[name]
                }
            })
            Object.keys(groupedByAccmerged).map(name => {
                if (name === value) {
                    obj2[name] = groupedByAccmerged[name]
                }
            })
            const combObj = {}
            combObj[value] = { ...obj[value], ...obj2[value] }
            combObj[value] = {
                ...combObj[value],
                OAplusComm: combObj[value].Comm + (combObj[value]?.OASF || 0),
                OAPlusrevperH: (combObj[value].Comm + (combObj[value]?.OASF || 0)) / combObj[value].hours
            }
            setARArray1(combObj);
        } else {
            const updatedObj1 = { ...data };
            Object.keys(updatedObj1).forEach(key => {
                if (updatedObj1[key]) {
                    // If key exists in obj1, add properties from ob2
                    updatedObj1[key] = { ...updatedObj1[key], ...groupedByAccmerged[key] };
                    updatedObj1[key] = {
                        ...updatedObj1[key],
                        OAplusComm: updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0),
                        OAPlusrevperH: (updatedObj1[key].Comm + (updatedObj1[key]?.OASF || 0)) / updatedObj1[key].hours
                    }
                }
            });
            const entries = Object.entries(updatedObj1);
            entries.sort((a, b) => b[1].OAPlusrevperH - a[1].OAPlusrevperH);
            const sortedData = Object.fromEntries(entries);
            setARArray1(sortedData);
        }
    }

    const roundValues = (dataArray, keys) => {
        return dataArray.map(item => {
            keys.forEach(key => {
                if (item.hasOwnProperty(key)) {
                    item[key] = parseFloat(item[key].toFixed(2));
                }
            });
            return item;
        });
    };

    const downloadCSV = () => {
        const data = Object.keys(aRArray1).map(email => ({
            email,
            ...aRArray1[email],
        }));
        const uniqueEmails = [...new Set(data.map(item => item.email))];
        const outputs = uniqueEmails.map((email, index) => {
            const filteredData = data.filter(item => item.email === email);
            let store = (data) => {
                return [{ email: storeNameList[data] }]
            }
            let emailOutput = [{ email }];
            let data1 = emailOutput[0].email.length === 7 ? store(emailOutput[0].email) : emailOutput
            const actKeys = [{ act: 'Act', actCount: 'Accessory Attached', actARate: 'Attach Rate(%)', actCost: 'Cost($)', actMSRP: 'MSRP($)', actDiscount: 'Discount($)', actSf: 'Sold for($)', actPm: 'Profit Margin($)', accActNumber: 'Accessories Numbers', accActNumRate: 'Accessories Numbers Rate(%)' }];
            const actOutput = filteredData.map(({ act, actCount, actARate, actSum, actSf, actPm, accActNumber, accActNumRate }) => ({
                act,
                actCount,
                actARate,
                ...actSum,
                actSf,
                actPm,
                accActNumber,
                accActNumRate
            }));
            const keysToRound = [
                'accActNumRate', 'cost', 'discount', 'actARate', 'actPm', 'actSf', 'msrp'
            ];
            const roundedDataAct = roundValues(actOutput, keysToRound);
            const hupKeys = [{ hup: 'Hup', hupCount: 'Accessory Attached', hupAr: 'Attach Rate(%)', hupCost: 'Cost($)', hupMSRP: 'MSRP($)', hupDiscount: 'Discount($)', hupSf: 'Sold for($)', hupPm: 'Profit Margin($)', accHupNumber: 'Accessories Numbers', accHupNumRate: 'Accessories Numbers Rate(%)' }];
            const hupOutput = filteredData.map(({ hup, hupCount, hupARate, hupSum, hupSf, hupPm, accHupNumber, accHupNumRate }) => ({
                hup,
                hupCount,
                hupARate,
                ...hupSum,
                hupSf,
                hupPm,
                accHupNumber,
                accHupNumRate
            }));
            const keysToRoundHup = [
                'accHupNumRate', 'cost', 'discount', 'hupARate', 'hupPm', 'hupSf', 'msrp'
            ];
            const roundedDataHup = roundValues(hupOutput, keysToRoundHup);
            const actPPPKeys = [{ act: 'Act', actPPP: 'Act with PPP', actPPPRate: 'PPP Rate(%)' }];
            const actPPPOutput = filteredData.map(({ act, actPPP, actPPPRate }) => ({ act, actPPP, actPPPRate }));
            const keysToRoundActPPP = ['actPPPRate'];
            const roundedDataActPPP = roundValues(actPPPOutput, keysToRoundActPPP);
            const hupPPPKeys = [{ hup: 'Hup', hupPPP: 'Hup with PPP', hupPPPRate: 'PPP Rate(%)' }];
            const hupPPPOutput = filteredData.map(({ hup, hupPPP, hupPPPRate }) => ({ hup, hupPPP, hupPPPRate }));
            const keysToRoundHupPPP = ['hupPPPRate'];
            const roundedDataHupPPP = roundValues(hupPPPOutput, keysToRoundHupPPP);
            const actWDKeys = [{ act: 'Act', actWD: 'Act with Device', actWDRate: 'Device Rate(%)' }]
            const actWDOutput = filteredData.map(({ act, actWD, actWDRate }) => ({ act, actWD, actWDRate }));
            const keysToRoundActWD = ['actWDRate'];
            const roundedDataActWD = roundValues(actWDOutput, keysToRoundActWD);

            return { data1, actKeys, roundedDataAct, hupKeys, roundedDataHup, actPPPKeys, roundedDataActPPP, hupPPPKeys, roundedDataHupPPP, actWDKeys, roundedDataActWD };
        });
        const combinedData = []
        Object.values(outputs).forEach(output => {
            Object.values(output).forEach(obj => {
                combinedData.push(Object.values(...obj));
            })
        })
        const csvContent = "data:text/csv;charset=utf-8," +
            combinedData.map(row => Object.values(row).join(",")).join("\n");

        // Create a link element and trigger the download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "AttachRate_Data.csv");
        document.body.appendChild(link);
        link.click();
    }
    const headers = [
        { key: 'Act', label: 'Activation' },
        { key: 'AA', label: 'Accessories Attached' },
        { key: 'AR', label: 'Attach Rate' },
        { key: 'Disc', label: 'Discount' },
        ...(userDetail?.role === 'admin' ? [{ key: 'PM', label: 'Profilt Margin' }] : []),
        { key: 'AccQty', label: 'Accessory Quantity' },
        { key: 'AAA', label: 'Accessory Quantity per Activation' },
        { key: 'PPP', label: 'Phone Protection Plan' },
        { key: 'PPPAtt', label: 'Phone Protection Plan Attach' },
        { key: 'WD', label: 'With Device' },
        { key: 'WDAtt', label: 'With Device Attach' },
        { key: 'HRS', label: 'Hours' },
        { key: 'AH', label: 'Activity Per Hour' },
        { key: 'Rev', label: 'Revenue' },
        { key: 'RH', label: 'Revenue Per Hour' }
    ];
    const headersbot = [
        { key: 'Hup', label: 'Hardware Upgrade' },
        { key: 'HA', label: 'Accessories Attached' },
        { key: 'HR', label: 'Attach Rate' },
        { key: 'Disc', label: 'Discount' },
        ...(userDetail?.role === 'admin' ? [{ key: 'PM', label: 'Profilt Margin' }] : []),
        { key: 'AccQty', label: 'Accessory Quantity' },
        { key: 'AAA', label: 'Accessory Quantity per HUP' },
        { key: 'PPP', label: 'Phone Protection Plan' },
        { key: 'PPPAtt', label: 'Phone Protection Plan Attach' },
        { key: 'OA No', label: 'Only Accesorries' },
        ...(userDetail?.role === 'admin' ? [{ key: 'OA PM', label: 'Only Accesorries Profit Margin' },] : []),


    ];

    return (
        <div className="ar-main">
            {userDetail?.role.length > 0 && userDetail?.role === "admin" ? <AdminSidebar /> : <Sidebar />}
            <div className="ar-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="ar-block">
                    <div className="ar-block-container">
                        <div className='filter-menu-list'>
                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <label htmlFor="startDate">From:</label>
                                    <input
                                        type="date"
                                        id="startDate"
                                        className='filter-datemenu-input'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <label htmlFor="endDate">To:</label>
                                    <input
                                        type="date"
                                        id="endDate"
                                        className='filter-datemenu-input'
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                    <Tooltip title="Search" arrow placement="top">
                                        <IconButton onClick={handleSearch} sx={{ ml: 1 }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            {userDetail?.role !== "employee" ?
                                (
                                    <div className='filter-menu'>
                                        <label htmlFor="employeeFilter">Employee:</label>
                                        <select id="employeeFilter" onChange={handleEmployeeNameChange}>
                                            <option value="">-- Select Employee --</option>
                                            {filterEmp && filterEmp.map((name, index) => (
                                                <option key={index} value={name}>
                                                    {capitalizeFirstLetter(name)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : (
                                    <></>
                                )}

                            <div className='filter-menu'>
                                <label htmlFor="storeFilter">Store:</label>
                                <select id="storeFilter" onChange={handleStoreNameChange}>
                                    <option value="">-- Select Store --</option>
                                    {storesList && storesList.map((name, index) => (
                                        <option key={index} value={name}>
                                            {storeNameList[name]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='topbuttons'>
                                <Tooltip title="Download" arrow placement="top">
                                    <IconButton sx={{ ml: 2 }} onClick={downloadCSV} >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="loading-overlay">
                                <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
                            </div>
                        ) : (
                            <>
                                <div className='table'>
                                    {Object.entries(aRArray1).map(([name, detail], index) => (
                                        <>
                                            <div className="table-container" key={index}>
                                                <div className='emp'>
                                                    <span>{capitalizeFirstLetter(name)}</span>
                                                </div>
                                                <div className='emp-table'>
                                                    <div className='table-act'>
                                                        <table className="professional-table" >
                                                            <thead>
                                                                <tr>
                                                                    {headers.map(header => (
                                                                        <Tooltip key={header.key} title={`${header.label}`} arrow placement="top">
                                                                            <th>{header.key}</th>
                                                                        </Tooltip>
                                                                    ))}
                                                                    {/* <th>Act</th>
                                                                    <th>A/A</th>
                                                                    <th>A/R</th>
                                                                    {userDetail?.role === 'manager' ? "" : (<th>Cost</th>)}
                                                                    <th>MSRP</th>
                                                                    <th>Disc</th>
                                                                    <th>S/F</th>
                                                                    {userDetail?.role === 'manager' ? "" : (<th>P/M</th>)}
                                                                    <th>Acc-Qty</th>
                                                                    <th>Avg-Acc/Act</th>
                                                                    <th>PPP</th>
                                                                    <th>PPP-Att</th>
                                                                    <th>W/D</th>
                                                                    <th>W/D-Att</th>
                                                                    <th>HRS</th>
                                                                    <th>Aty/H</th>
                                                                    <th>Rev</th>
                                                                    <th>R/H</th> */}
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>{detail?.act}</td>
                                                                    <td>{detail?.actCount}</td>
                                                                    <td>{detail?.act === 0 ? 0 : detail?.actARate?.toFixed(2)}%</td>
                                                                    {/* {userDetail?.role === 'manager' ? "" : (<td>${detail.actSum.cost.toFixed(2)}</td>)}
                                                                    <td>${detail.actSum.msrp.toFixed(2)}</td> */}
                                                                    <td>${detail?.actSum?.discount?.toFixed(2)}</td>
                                                                    {/* <td>${detail.actSf.toFixed(2)}</td> */}
                                                                    {userDetail?.role === 'admin' ? (<td>${detail?.actPm?.toFixed(2)}</td>) : ""}
                                                                    <td>{detail?.accActNumber}</td>
                                                                    <td>{detail?.act === 0 ? 0 : detail?.accActNumRate?.toFixed(2) || 0}</td>
                                                                    <td>{detail?.actPPP}</td>
                                                                    <td>{detail?.act === 0 ? 0 : detail?.actPPPRate?.toFixed(2) || 0}%</td>
                                                                    <td>{detail?.actWD}</td>
                                                                    <td>{detail?.act === 0 ? 0 : detail?.actWDRate?.toFixed(2)}%</td>
                                                                    <td>{detail?.hours?.toFixed(2)}</td>
                                                                    <td>{detail?.actperH?.toFixed(2)}</td>
                                                                    <td>${detail?.OAplusComm?.toFixed(2)}</td>
                                                                    <td style={{ backgroundColor: 'yellow' }}>${detail?.OAPlusrevperH ? detail?.OAPlusrevperH?.toFixed(2) : 0}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='table-hup'>

                                                        <table className="professional-table">
                                                            <thead>
                                                                <tr>
                                                                    {headersbot.map(header => (
                                                                        <Tooltip key={header.key} title={`${header.label}`} arrow placement="top">
                                                                            <th>{header.key}</th>
                                                                        </Tooltip>
                                                                    ))}
                                                                    {/* <th>HUP</th>
                                                                    <th>H/A</th>
                                                                    <th>A/R</th>
                                                                    {userDetail?.role === 'manager' ? "" : (<th>Cost</th>)}
                                                                    <th>MSRP</th>
                                                                    <th>Disc</th>
                                                                    <th>S/F</th>
                                                                    {userDetail?.role === 'manager' ? "" : (<th>P/M</th>)}
                                                                    <th>Acc-Qty</th>
                                                                    <th>Avg-Acc/Hup</th>
                                                                    <th>PPP</th>
                                                                    <th>PPP-Att</th>
                                                                    <th>HRS</th>
                                                                    <th>Hup/H</th>
                                                                    <th>Rev</th>
                                                                    <th>R/H</th>
                                                                    <th>OA No</th>
                                                                    <th>OA PM</th> */}
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <tr>

                                                                    <td>{detail?.hup}</td>
                                                                    <td>{detail?.hupCount}</td>
                                                                    <td>{detail?.hup === 0 ? 0 : detail?.hupARate?.toFixed(2)}%</td>
                                                                    {/*  {userDetail?.role === 'manager' ? "" : (<td>${detail.hupSum.cost.toFixed(2)}</td>)}
                                                                    <td>${detail.hupSum.msrp.toFixed(2)}</td> */}
                                                                    <td>${detail?.hupSum?.discount?.toFixed(2)}</td>
                                                                    {/*  <td>${detail.hupSf.toFixed(2)}</td> */}
                                                                    {userDetail?.role === 'admin' ? (<td>${detail?.hupPm?.toFixed(2)}</td>) : ""}
                                                                    <td>{detail?.accHupNumber}</td>
                                                                    <td>{detail?.hup === 0 ? 0 : detail?.accHupNumRate?.toFixed(2)}</td>
                                                                    <td>{detail?.hupPPP}</td>
                                                                    <td>{detail?.hup === 0 ? 0 : detail?.hup === 0 ? 0 : detail?.hupPPPRate?.toFixed(2)}%</td>
                                                                    {/* <td>20</td>
                                                                    <td>60%</td>
                                                                    <td>$1,500</td>
                                                                    <td>$75</td> */}
                                                                    <td>{detail?.OAtotal ? detail?.OAtotal : 0}</td>
                                                                    {userDetail?.role === 'admin' ? (<td>${detail?.OAPM ? detail?.OAPM?.toFixed(2) : 0}</td>) : ""}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* <div className='table-hup'>
                                                        <table className="professional-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Act</th>
                                                                    <th>PPP Att.</th>
                                                                    <th>Att %</th>

                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{detail.act}</td>
                                                                    <td>{detail.actPPP}</td>
                                                                    <td>{detail.act === 0 ? 0 : detail.actPPPRate.toFixed(2) || 0}%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='table-hup'>
                                                        <table className="professional-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Hup</th>
                                                                    <th>PPP Att.</th>
                                                                    <th>Att %</th>

                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{detail.hup}</td>
                                                                    <td>{detail.hupPPP}</td>
                                                                    <td>{detail.hup === 0 ? 0 : detail.hup === 0 ? 0 : detail.hupPPPRate.toFixed(2)}%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='table-hup'>
                                                        <table className="professional-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Act</th>
                                                                    <th>w/Device</th>
                                                                    <th>Att %</th>

                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{detail.act}</td>
                                                                    <td>{detail.actWD}</td>
                                                                    <td>{detail.act === 0 ? 0 : detail.actWDRate.toFixed(2)}%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AttachRate