import React, { useState } from 'react'
import './EmpHours.scss'
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import ReactLoading from 'react-loading';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
/* import DownloadIcon from '@mui/icons-material/Download'; */
import * as XLSX from 'xlsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadModal from './UploadModal';
import { getHoursWorkedData } from '../../helper/helper'

const EmpHours = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];

    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [apiData, setApiData] = useState([]);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleSearch = async () => {
        setIsLoading(true);
        const { data } = await getHoursWorkedData({ startDate, endDate });
        console.log(data, startDate, endDate)
        setApiData(data);
    }

    if (isLoading && apiData?.length > 0) {
        setIsLoading(false);
    }

    const StoreTable = ({ storeHours, empHours }) => {

        const sortedDataStore = storeHours?.sort((a, b) => b[1] - a[1]);
        const sortedDataEmp = empHours?.sort((a, b) => b[1] - a[1]);
        return (
            <>
                <div className='storeTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>Store</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDataStore?.map((store, index) => (
                                <tr key={index}>
                                    <td>{store[0]}</td>
                                    <td>{store[1].toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="empTable">
                    <table>
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedDataEmp?.map((emp, index) => (
                                <tr key={index}>
                                    <td>{emp[0]}</td>
                                    <td>{emp[1].toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };
    return (
        <div className="eh-main">
            <AdminSidebar />
            <div className="eh-container">
                <div className='nav-container'>
                    <Navbar />
                </div>
                <div className="eh-block">
                    <div className="eh-block-container">
                        <div className='filter-menu-list'>
                            <div className='filter-menu'>
                                <div className='filter-datemenu'>
                                    <label htmlFor="startDate">From:</label>
                                    <input
                                        type="date"
                                        id="startDate"
                                        className='filter-datemenu-input'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                    <label htmlFor="endDate">To:</label>
                                    <input
                                        type="date"
                                        id="endDate"
                                        className='filter-datemenu-input'
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                    <Tooltip title="Search" arrow placement="top">
                                        <IconButton onClick={handleSearch} sx={{ ml: 1 }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>

                            {/* <div className='topbuttons'>
                                <Tooltip title="Download">
                                    <IconButton sx={{ ml: 2 }} onClick={downloadCSV} >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
                            <div className="upload-button-container">
                                <UploadModal
                                    modalIsOpen={modalIsOpen}
                                    closeModal={closeModal}
                                />
                                <Tooltip title="Upload Hours File" arrow placement="top">
                                    <IconButton sx={{ ml: 2 }} onClick={openModal}>
                                        <FileUploadIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="loading-overlay">
                                <ReactLoading type='spinningBubbles' color='white' height={'10%'} width={'10%'} />
                            </div>
                        ) : (
                            <>
                                <div className="datatable">
                                    <StoreTable storeHours={apiData[0]?.storeHours} empHours={apiData[0]?.empHours} />
                                </div>

                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmpHours