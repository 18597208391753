import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Recovery from './components/Recovery/Recovery';
import ResetPassword from './components/ResetPassword/ResetPassword';
import PageNotFound from './components/Pages/PageNotFound';
import Login from '../src/Container/Login/Login'
import Employee from '../src/Container/Employee/Employee'
import Admin from '../src/Container/Admin/AdminHome'
import Manager from '../src/Container/Manager/Manager';
import UserList from '../src/Container/UserList/UserList';
import AddUser from '../src/Container/AddUser/AddUser';
import ScoreCard from '../src/Container/ScoreCard/ScoreCard'
import Targets from '../src/Container/Targets/Targets';
import ChangePassword from '../src/components/ChangePassword/ChangePassword'
import EditProfile from '../src/components/EditProfile/EditProfile';
import CommissionStructure from '../src/Container/CommissionStructure/CommissionStructure';
import Refund from '../src/Container/Refund/Refund';
import BusinessReport from '../src/Container/BusinessReport/BusinessReport';
import FinalCommission from '../src/Container/FinalCommission/FinalCommission';
import CisUpload from '../src/Container/CisUpload/CisUpload';
import ManualInputData from './Container/AccessorySKU/ManualInputData';
import EmployeeCommissionReport from '../src/Container/EmployeeCommissionReport/EmployeeCommissionReport';
import RankingPage from '../src/Container/RankingPage/RankingPage';
import Clockin from '../src/Container/Clockin/Clockin';
import AttachRate from '../src/Container/AttachRate/AttachRate'
import EmpHours from '../src/Container/EmpHours/EmpHours'
import HomeInternet from '../src/Container/HomeInternet/HomeInternet'
import ViewAR from '../src/Container/ViewAR/ViewAR'
import EventCis from '../src/Container/EventCis/EventCis'

/** auth middleware */
import { AuthorizeUser, ProtectRoute } from './middleware/auth'


/** root routes */
const router = createBrowserRouter([
  {
    path: '/',
    element: <Login></Login>
  },
  {
    path: '/employee',
    element: <AuthorizeUser><Employee /></AuthorizeUser>
  },
  {
    path: '/admin',
    element: <AuthorizeUser><Admin></Admin></AuthorizeUser>
  },
  {
    path: '/userlist',
    element: <AuthorizeUser><UserList /></AuthorizeUser>
  },
  {
    path: 'adduser',
    element: <AuthorizeUser><AddUser /></AuthorizeUser>
  },
  {
    path: '/recovery',
    element: <ProtectRoute><Recovery></Recovery></ProtectRoute>
  },
  {
    path: '/reset',
    element: <ProtectRoute><ResetPassword></ResetPassword></ProtectRoute>
  },
  {
    path: '*',
    element: <PageNotFound></PageNotFound>
  },
  {
    path: '/manager',
    element: <AuthorizeUser><Manager></Manager></AuthorizeUser>
  },
  {
    path: 'scorecard',
    element: <AuthorizeUser><ScoreCard /></AuthorizeUser>
  },
  {
    path: 'targets',
    element: <AuthorizeUser><Targets /></AuthorizeUser>
  },
  {
    path: 'changepassword',
    element: <ProtectRoute><AuthorizeUser><ChangePassword></ChangePassword></AuthorizeUser></ProtectRoute>
  },
  {
    path: 'editprofile',
    element: <AuthorizeUser><EditProfile></EditProfile></AuthorizeUser>
  },
  {
    path: 'commissionstructure',
    element: <AuthorizeUser><CommissionStructure></CommissionStructure></AuthorizeUser>
  },
  {
    path: 'refund',
    element: <AuthorizeUser><Refund></Refund></AuthorizeUser>
  },
  {
    path: 'businessreport',
    element: <AuthorizeUser><BusinessReport></BusinessReport></AuthorizeUser>
  },
  {
    path: 'finalcommission',
    element: <AuthorizeUser><FinalCommission></FinalCommission></AuthorizeUser>
  },
  {
    path: 'cisupload',
    element: <AuthorizeUser><CisUpload></CisUpload></AuthorizeUser>
  },
  {
    path: 'manualinputdata',
    element: <AuthorizeUser><ManualInputData></ManualInputData></AuthorizeUser>
  }, {
    path: 'employeeCommissionReport',
    element: <AuthorizeUser><EmployeeCommissionReport></EmployeeCommissionReport></AuthorizeUser>
  },
  {
    path: 'rankingPage',
    element: <AuthorizeUser><RankingPage></RankingPage></AuthorizeUser>
  },
  {
    path: 'clockin',
    element: <AuthorizeUser><Clockin></Clockin></AuthorizeUser>
  },
  {
    path: 'attachrate',
    element: <AuthorizeUser><AttachRate></AttachRate></AuthorizeUser>
  },
  {
    path: 'emphours',
    element: <AuthorizeUser><EmpHours></EmpHours></AuthorizeUser>
  },
  {
    path: 'homeinternet',
    element: <AuthorizeUser><HomeInternet></HomeInternet></AuthorizeUser>
  },
  {
    path: 'viewar',
    element: <AuthorizeUser><ViewAR></ViewAR></AuthorizeUser>
  },
  {
    path: 'eventcis',
    element: <AuthorizeUser><EventCis></EventCis></AuthorizeUser>
  }
])


export default function App() {

  return (
    <main className='main_wrapper'>
      <RouterProvider router={router}></RouterProvider>
    </main>
  )
}