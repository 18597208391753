import React from 'react'
import './Barcharts.scss';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const Barcharts = ({ target, achieved, dataEvent=[], currentFilter }) => {

    const data = [
        {
            name: 'Total',
            Target: target.postpaidtarget + target.prepaidtarget,
            Achieved: achieved.postCount + achieved.preCount + (currentFilter?.length === 2 ? 0 : (dataEvent.postCount + dataEvent.preCount)),
        },
        {
            name: 'Postpaid',
            Target: target.postpaidtarget,
            Achieved: achieved.postCount + (currentFilter?.length === 2 ? 0 : dataEvent.postCount),
        },
        {
            name: 'Prepaid',
            Target: target.prepaidtarget,
            Achieved: achieved.preCount + (currentFilter?.length === 2 ? 0 : dataEvent.preCount),
        },
        {
            name: 'Hup',
            Target: target.hardwareupgrade,
            Achieved: achieved.hupCount + (currentFilter?.length === 2 ? 0 : dataEvent.hupCount),
        },
        {
            name: 'Addons',
            Target: target.addon,
            Achieved: achieved.addonsCount + (currentFilter?.length === 2 ? 0 : dataEvent.addonsCount),
        },
    ];
    return (
        <div className='block'>
            <div className="block-head">
                <div className="block-title">
                    <span>Target vs Achieved</span>
                </div>
            </div>

            <div className="line-chart">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Target" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                        <Bar dataKey="Achieved" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default Barcharts